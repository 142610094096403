import React, { Component, JSXElementConstructor, Fragment } from 'react';
import ReactHtmlParser from 'react-html-parser'; 
import { Redirect, Link } from "react-router-dom";
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import {ApplicationState} from '../../store';
import {Cart, CartTypes, ProductType} from '../../store/ducks/cart/types';
import * as CartActions from '../../store/ducks/cart/actions';
import {Param, ParamTypes} from '../../store/ducks/param/types';
import * as ParamActions from '../../store/ducks/param/actions';
import {Helmet} from "react-helmet";
import moment from "moment";
import 'moment/locale/pt-br';
import Api from '../../services/api';
import {Diversos} from '../../services/diversos';
import './index.scss';
import ReactDOM from 'react-dom';

import Lottie from 'react-lottie';
import successGif from '../../assets/animations/success.json'
import Loading from '../../assets/animations/loading.json'

// import de Componentes
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Product from '../../components/Product';


// import de Imagens/SVG
import { ReactComponent as Needle } from '../../assets/Icons/needle.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle, faPlus } from '@fortawesome/free-solid-svg-icons';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import image1 from '../../assets/Images/image-1.png'
import image2 from '../../assets/Images/image-2.png'
import image3 from '../../assets/Images/image-3.png'



// import de Componentes do Bootstrap
import Card from 'react-bootstrap/Card'
import CardDeck from 'react-bootstrap/CardDeck'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Modal from 'react-bootstrap/Modal'
import Carousel from 'react-bootstrap/Carousel'
import Dropdown from 'react-bootstrap/Dropdown'
import Pagination from 'react-bootstrap/Pagination'
import PageItem from 'react-bootstrap/PageItem'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import Image from 'react-bootstrap/Image'
import Container from 'react-bootstrap/Container'


interface StateProps { 
  cart?: Cart;
  param: Param;
} 

interface DispatchProps {
  add(cart: ProductType): void;
  update(cart: ProductType): void;
  drop(rowid: string): void;
  setParam(data: Param): void;
}

// interface OwnProps {
//   imageOver: any,
//   imageNormal: any,
// } 

type Props = StateProps & DispatchProps;
 
class Produto extends Component<Props> {

  api: any = null;
  state: any = null;
  static: any

  constructor (props) {
    super(props);
    this.api = new Api();
    const {nome} = props.match.params;
    this.state = {
      paramNome: nome,
      paramId: null,
      isLoading: true,
      produto: false,
      quantity: 1,
      activeIndex: 0,
    }

    // this.next = this.next.bind(this);
    // this.previous = this.previous.bind(this);

  }

  async componentDidMount () {
    const self = this;

    if ( !self.state.paramNome ) {
      window.location.href = "/404";
      return;
    }

    self.setState({isLoading: true});

    try {
      const {data} = await self.api.get(`/product/${self.state.paramNome}/filter/name`);

      if ( !data.status ) {
        throw new Error('Produto não localizado');
      } else {
        self.setState({produto: data.msg});
        console.log(data.msg);
      }

    } catch (e) {
      console.error(e);
      window.location.href = "/404";
    } finally {
      self.setState({isLoading: false});
    }
  }

  private async handleAddCart (event) {
    const self = this;
    event.preventDefault();

    if (this.state.quantity <= 0) {
      return window.alert("Selecione ao menos 1 item para concluir a compra");
    }

    else {

      let preco = self.state.produto.PRECO;
      let precoOriginal = self.state.produto.PRECO;

      if ((self.state.produto.PREPRO > 0) && (self.state.produto.PREPRO < self.state.produto.PRECO) && (moment(self.state.produto.INIPRO) <= moment()) && (moment(self.state.produto.FIMPRO) >= moment())) {
        preco = self.state.produto.PREPRO;
      }

      // if (self.state.produto.CODIGO )
      self.props.add({
        codigo: self.state.produto.CODIGO,
        nome: self.state.produto.NOME,
        priceOrigin: precoOriginal,
        price: preco,
        qty: this.state.quantity,
        estoque: this.state.produto.ESTOQUE,
        peso: (!this.state.produto.PESO) ? 0.01 : this.state.produto.PESO,
        foto: ((!this.state.produto.FOTOS) || (!this.state.produto.FOTOS[0])) ? `${process.env.REACT_APP_BASE_URL_PICTURE}/produto-sem-imagem.png` : `${process.env.REACT_APP_BASE_URL_PICTURE}/${this.state.produto.FOTOS[0].link}`
      });
      self.props.setParam({ ...self.props.param, cartOpened: true });
    }
  }

  private IncreaseItem = () => {
    this.setState(prevState => {
      if (this.state.quantity < this.state.produto.ESTOQUE) {
        return {
          quantity: this.state.quantity + 1
        }
      }

      else {
        return null;
      }
    });
  }

  private DecreaseItem = () => {
    this.setState(prevState => {
      if (this.state.quantity > 1) {
        return {
          quantity: this.state.quantity - 1
        }
      }

      else {
        return null;
      }
    });
  }

  goToIndex(newIndex) {
    // if (this.animating) return;
    return this.setState({ activeIndex: newIndex });
  }

  handleChange = (event) => {
    this.setState({quantity: event.target.value});
  }

//   previous () {
//     let items = this.state.produto.FOTOS.length;
//     const nextIndex =
//       this.state.activeIndex === 0 ?
//         items.length - 1
//       :
//         this.state.activeIndex - 1;
//     this.setState({ activeIndex: nextIndex });
//   }

//   next () {
//     let items = this.state.produto.FOTOS.length;
//     const nextIndex =
//       this.state.activeIndex === items.length - 1 ?
//         0
//       :
//         this.state.activeIndex + 1;
//     this.setState({ activeIndex: nextIndex });
//   }

//   handleSelect (selectedIndex) {
//     this.setState({
//       activeIndex: selectedIndex
//     });
//   }
  

  private _henderSEOTags () {
    if ( this.state.isLoading ) {
      return (<></>);
    } else {
      const title = `${Diversos.capitalize(this.state.produto.NOME)} - ${process.env.REACT_APP_TITLE}`;
      const url = `${process.env.REACT_APP_URL}/produto/${Diversos.toSeoUrl(this.state.produto.NOME)}/${this.state.produto.CODIGO}`;
      const description = `Oferta de ${Diversos.capitalize(this.state.produto.NOME)} para compra online - ${process.env.REACT_APP_TITLE}`;
      const detalhes = ((this.state.produto.COMPLEMENTO) && (this.state.produto.COMPLEMENTO.DESCRICAO1)) ? this.state.produto.COMPLEMENTO.DESCRICAO1 : description;
      const image = ((this.state.produto.FOTOS) && (this.state.produto.FOTOS[0])) ? `${process.env.REACT_APP_BASE_URL_PICTURE}}/${this.state.produto.FOTOS[0].link}` : `${process.env.REACT_APP_BASE_URL_PICTURE}}/produto-sem-foto.png`;

      return (
        <Helmet>
          <title>{title}</title>
          <link rel="canonical" href={url} />
          <meta name="url" content={url} />
          <meta name="robots" content="index"/>
          <meta name="description" content={description} />
          <meta name="autor" content="TecWorks" />
          <meta name="company" content="TecWorks" />
          <meta name="revisit-after" content="2" />

          {/* LD+JSON  */}
          <script type="application/ld+json">
            {`
              {
                "@context": "http://schema.org/",
                "@type": "Product",
                "name": "${this.state.produto.NOME}",
                "image": "${image}",
                "description": "${detalhes}",
                "mpn": "${this.state.produto.CODIGO}",
                "brand": {
                    "@type": "Thing",
                    "name": "${this.state.produto.MARCA}"
                },
                "offers": {
                    "@type": "Offer",
                    "priceCurrency": "BRL",
                    "price": "${this.state.produto.PRECO}",
                    "priceValidUntil": "'. $ldJson->priceValidUntil .'",
                    "itemCondition": "http://schema.org/UsedCondition",
                    "availability": "${(this.state.produto.ESTOQUE > 0) ? 'http://schema.org/InStock' : 'http://schema.org/OutOfStock'}",
                    "seller": {
                        "@type": "Organization",
                        "name": "${process.env.REACT_APP_TITLE}"
                    }
                }
              }
            `}
          </script>

          {/* TWITTER CARDS */}
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:site" content={url} />
          <meta name="twitter:title" content={title} />
          <meta name="twitter:description" content={detalhes} />
          <meta name="twitter:image" content={image} />

          {/* FACEBOOK CARDS */}
          {/* <meta property="fb:app_id" content="270229570743012" /> */}
          <meta property="og:site_name" content={title}/>
          <meta property="og:locale" content="pt_BR" />
          <meta property="og:type" content="website"/>
          <meta property="og:url" content={url}/>
          <meta property="og:title" content={title}/>
          <meta property="og:image" content={image} />
          <meta property="og:description" content={detalhes}/>
          <meta property="og:product:price:amount" content={this.state.produto.PRECO}/>
          <meta property="og:product:price:currency" content="BRL"/>
          <meta property="og:product:brand" content={this.state.produto.MARCA}/>
          <meta property="og:product:condition" content="new"/>
          <meta property="og:product:availability" content={this.state.produto.ESTOQUE > 0 ? 'in stock' : 'out stock'}/>
        </Helmet>
      );
    }
  }

  handleSelect = (selectedIndex, e) => {
    this.setState({
      activeIndex: selectedIndex
    });
  };

  render () {
    return (
      <>
        {/* {this._henderSEOTags()} */}
        <Header/>
        
          {/* <Row className="breadcrumb-container">
            <Breadcrumb>
              <Breadcrumb.Item href="#"> Home </Breadcrumb.Item>
              <Breadcrumb.Item href="#"> Categorias </Breadcrumb.Item>
              <Breadcrumb.Item active> Agulhas </Breadcrumb.Item>
            </Breadcrumb>
          </Row> */}

          {
            (this.state.isLoading) ? 
              <>
                <section className="loading-screen-overlapped"/>
                <Modal
                show="true"
                // onHide={() => this..setParam({ ...this.props.param, cartOpened: false })}
                dialogClassName="loading-screen"
                className="px-0 loading-modal-container"
                aria-labelledby="Loading-screen">
                  <Lottie 
                    options={{
                      loop: true,
                      autoplay: true, 
                      animationData: Loading,
                    }}
                    height={300}
                    width={300}
                    isStopped={false}
                    isPaused={false}
                  />
                </Modal>
              </>
              :
              (!this.state.produto) ?
              <p> produto nao encontrado </p>
              :
            <>
              <section className="product-section">
                <Row className="pt-5 product-info-container">
                  <Col lg={6} className="image-tag">
                    {/* <Image src={croche} rounded fluid /> */}
                    <Carousel onSelect={this.handleSelect} interval={null} indicators={false} controls={true} activeIndex={this.state.activeIndex} defaultActiveIndex={0}
                    // onSelect={this.handleSelect}
                    // next={this.next} previous={this.previous}
                    // nextIcon={
                    //   <span aria-hidden="true" className="carousel-control-next-icon" onSelect={this.next}/>
                    // }
                    // prevIcon={
                    //   <span aria-hidden="true" className="carousel-control-next-icon" onSelect={this.previous}/>
                    // }
                    >
                      {
                        this.state.produto.FOTOS.map((row, index) => (
                          <Carousel.Item key={index}>
                            <img
                              className="d-block w-100 product-image"
                              src={`${process.env.REACT_APP_BASE_URL_PICTURE}/${row.link}`}
                              alt={`${process.env.REACT_APP_BASE_URL_PICTURE}/${row.link}`}
                            />
                          </Carousel.Item>
                        ))
                      }
                    </Carousel>
                    <ol className="carousel-indicators">
                    {
                      this.state.produto.FOTOS.map((row, index) => (
                        <li
                          key={index}
                          onClick={() => this.goToIndex(index)}
                          className={`carousel-indicator ${this.state.activeIndex === index ? "carousel-active" : ""}`}>
                          <img
                          className="d-block w-100 carousel-thumbnail"
                          src={`${process.env.REACT_APP_BASE_URL_PICTURE}/${row.link}`}
                          alt={`${process.env.REACT_APP_BASE_URL_PICTURE}/${row.link}`}
                          />
                        </li>
                    ))}
                    </ol>
                  </Col>
                  <Col lg={6} className="product-tags">
                    <h1 className="name-tag"> {this.state.produto.NOME} </h1>
                    <Row>
                      <Col lg={12} xs={12}> 
                        <p className="brand-tag"> Marca: <span>{this.state.produto.MARCA}</span> </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12} xs={12}>
                        <p className="brand-tag"> Código: <span> {this.state.produto.CODIGO} </span> </p>
                      </Col>
                    </Row>
                    
                    {
                      ((this.state.produto.PREPRO > 0) && (this.state.produto.PREPRO < this.state.produto.PRECO) && (moment(this.state.produto.INIPRO) <= moment()) && (moment(this.state.produto.FIMPRO) >= moment())) ? 
                      <>
                        <p className="product-price-alt"><strong> R$ {Diversos.number_format(this.state.produto.PRECO,2,",","")} </strong></p>
                        <h3 className="price-tag"> R$ {Diversos.number_format(this.state.produto.PREPRO,2,",","")} </h3> 
                      </>
                      :
                      <h3 className="price-tag"> R$ {Diversos.number_format(this.state.produto.PRECO,2,",","")} </h3>
                    }

                    <p> 
                      {
                        (parseFloat(this.state.produto.ESTOQUE) > 0) ? 
                        <>
                          <FontAwesomeIcon icon={faCheckCircle} style={{marginRight: 5}}/> 
                          Produto disponível
                        </>
                        :
                        <>
                          <FontAwesomeIcon icon={faTimesCircle} style={{marginRight: 5}}/> 
                          Produto indisponível
                        </>
                      }
                      
                    </p>
                    
                    {
                      (parseFloat(this.state.produto.ESTOQUE) > 0) ? 
                      <Container className="mt-5">
                        <Row className="align-items-center amount-container">
                          <span className="d-flex">              
                            <Col className="d-flex clear-padding amount-controller">
                              <button className="amount-btn" onClick={this.DecreaseItem}> - </button>
                            </Col>
                            <Col className="d-flex clear-padding">
                              <input type="number"
                              className="amount-input"
                              // id={this.state.quantity > 99 ? "smaller-font" : ""}
                              name="quantidade"
                              value={this.state.quantity}
                              // onChange={this.handleChange}
                              min="1"
                              max="9999"
                              step="1"
                              defaultValue="1" 
                              readOnly={true}
                              />
                            </Col>
                            <Col className="d-flex clear-padding amount-controller">
                              <button className="amount-btn" onClick={this.IncreaseItem}> + </button>
                            </Col>
                          </span>
                        </Row>
                        <Row className="buy-container">
                          <Col lg={6} className="p-0">
                            <Button className="cart-primary-action buy-action" size="lg" onClick={this.handleAddCart.bind(this)}>Comprar</Button>
                          </Col>
                        </Row>
                      </Container>
                      :
                      <Container className="mt-5">
                        <Row className="buy-container">
                          <Col className="p-0">
                            <Button className="cart-primary-action bg-secondary" size="lg">Avisa-me quando chegar</Button>
                          </Col>
                        </Row>
                      </Container>
                    }
                  </Col>
                </Row>
              </section>
              <div className="product-details">
                <h2 className="details-heading"> Detalhes do produto </h2>
                <p style={{whiteSpace: "pre-wrap"}}>{(!this.state.produto.COMPLEMENTO) ? "Nenhuma informação adicional" : ReactHtmlParser(this.state.produto.COMPLEMENTO.DESCRICAO1)}</p>
                {/* <h3> {(!this.state.produto.COMPLEMENTO) ? "" : this.state.produto.COMPLEMENTO.DESCRICAO2} </h3> */}
                <p style={{whiteSpace: "pre-wrap"}}>{(!this.state.produto.COMPLEMENTO) ? "" : ReactHtmlParser(this.state.produto.COMPLEMENTO.DESCRICAO2)}</p>
                {/* <h3> {(!this.state.produto.COMPLEMENTO) ? "" : this.state.produto.COMPLEMENTO.DESCRICAO3} </h3> */}
                <p style={{whiteSpace: "pre-wrap"}}>{(!this.state.produto.COMPLEMENTO) ? "" : ReactHtmlParser(this.state.produto.COMPLEMENTO.DESCRICAO3)}</p>
                {/* <h3> {(!this.state.produto.COMPLEMENTO) ? "" : this.state.produto.COMPLEMENTO.DESCRICAO4} </h3> */}
                <p style={{whiteSpace: "pre-wrap"}}>{(!this.state.produto.COMPLEMENTO) ? "" : ReactHtmlParser(this.state.produto.COMPLEMENTO.DESCRICAO4)}</p>
              </div>
              <div className="similar-products">
                <h2 className="details-heading"> Produtos similares </h2>
                {/* Vitrine de produtos */}
              </div>
            </>
          }
          
        <Footer/>
      </>
    );


  }
  
}

const mapStateToProps = (state: ApplicationState) => ({
  cart: state.cart.data,
  param: state.param.data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({...CartActions, ...ParamActions}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Produto);