import React from 'react';
import './index.scss';

import { Redirect } from "react-router-dom";
import Api from '../../services/api';
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import {ApplicationState} from '../../store';
import {User} from '../../store/ducks/user/types';
import * as UserActions from '../../store/ducks/user/actions';

import { Col, Accordion, Card } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle, faChevronDown, faChevronUp, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';


interface DispatchProps {
  doLogout(): void;
}

interface StateProps { 
  user: User;
} 

type Props = DispatchProps & StateProps;

class UserSidebar extends React.Component<Props> {

  state: any = null;
  api: any = null;

  constructor(props) {
    super(props);

    this.api = new Api();

    this.state = {
      redirect: null,
      customer: null,
      modalDetails: false,
      accordionCollapse: true,
      statusPedido: ""
    }

    // this.handlePageReload = this.handlePageReload.bind(this);
  }

  componentDidMount () {
    const self = this; 

    if (( self.props.user ) && ( self.props.user.status !== false )) { 
      self.getCustomer();
    }
  }

  private async handleLogout () {
    const self = this;
    self.props.doLogout();
    self.setState({redirect: "/"});
  }

  private async getCustomer () {
    const self = this;

    self.setState({isLoadingCustomer: true});

    try {
      const {data} = await self.api.get(`/customer/${self.props.user.codigo}`);

      if ( data.status === false ) {
        throw new Error('Cadastro de cliente não localizado.');
      } else {
        self.setState({customer: data.msg});
      }

    } catch (e) {
      console.error(e);

      self.setState({
        customer: null,
        customerHasError: true,
        customerHasErrorTitle: 'Cadastro não localizado',
        customerHasErrorMsg: e.message
      });

    } finally {
      self.setState({isLoadingCustomer: false});
    }
  }

  // handlePageReload () {
  //   const self = this;
  //   if ( (window.location.pathname) === "/meu-cadastro" ) {
  //     self.setState({
  //       redirect: 'meu-cadastro#trocar-senha'
  //     });
  //   }
  // }

  render () {

    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }

    return (
      <>
        <Col lg={3} xl={2} className="sidebar-col">
          <div className="meus-pedidos-sidebar">

            <span className="user-pic">
              <FontAwesomeIcon icon={faUserCircle}/>
              <h5 className="text-center">
                Olá <strong> 
                { this.state.customer && `${this.state.customer.nome }` } 
                </strong>
              </h5>
            </span>

            <hr/>

            <Accordion className="central-acordeao" defaultActiveKey="0" style={{width: "100%"}}>
              <Card>
                <Accordion.Toggle
                as={Card.Header}
                eventKey="0"
                onClick={() => this.setState({accordionCollapse: !this.state.accordionCollapse})}
                className={`central-acordeao-header ${(this.state.accordionCollapse) ? "has-collapsed" : ""}`}>
                  CENTRAL DO CLIENTE <FontAwesomeIcon icon={this.state.accordionCollapse ? faChevronUp : faChevronDown}/>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    {/* <a href="/central-do-cliente" style={{marginTop: '1rem'}}> Central do Cliente </a> */}
                    <a href="/perguntas-frequentes" style={{marginTop: '1rem'}}> Perguntas Frequentes </a>
                    <a href="/cadastro" style={{marginTop: '1rem'}}> Minha Conta </a>
                    {/* <a href="/meus-pedidos" style={{marginTop: '1rem'}}> Meus Pedidos </a> */}
                    {/* <a href="/meu-cadastro#trocar-senha" 
                    onClick={() => this.handlePageReload()}
                    style={{marginTop: '1rem'}}> Reset de Senha </a> */}
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>

            <a href="/fale-conosco"> Entrar em Contato </a>

            <hr/>

            <a href="/modo-de-entrega"> Modo de Entrega </a>

            <hr/>

            <a href="/politica-de-troca-e-devolucao"> Trocas e Devoluções </a>

            <hr/>

            <a href="/" title="Fazer Logout" className="fazer-logout" onClick={this.handleLogout.bind(this)}>
              Sair <FontAwesomeIcon icon={faSignOutAlt}/>
            </a>

          </div>
        </Col>
      </>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({...UserActions}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserSidebar);
