import React, { Component, JSXElementConstructor } from 'react';
import { Redirect, Link, useParams } from "react-router-dom";
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import { compose } from "recompose";
import moment from "moment";
import queryString from 'query-string';
import Pagination from "react-js-pagination";
import {ApplicationState} from '../../store';
import {User, UserTypes} from '../../store/ducks/user/types';
import * as UserActions from '../../store/ducks/user/actions';
import {Param, ParamTypes} from '../../store/ducks/param/types';
import * as ParamActions from '../../store/ducks/param/actions';
import Api from '../../services/api';
import {Diversos} from '../../services/diversos';
import './index.scss';
import ReactDOM from 'react-dom';

import Modal from 'react-bootstrap/Modal'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import FilterModal from '../../components/FilterModal';
import Product from '../../components/Product';
declare var window;

interface RouteParams {
  menu1: string
  menu2: string
  menu3: string
}

class Pesquisa extends Component<{}> {

  api: any = null;
  state: any = {};

  constructor (props) {
    super(props);
    this.api = new Api();
    const {pag} = queryString.parse(props.location.search);
    const {termo} = props.match.params;
    this.state = {
      paramTermo: termo.replace(/-/g," "),
      isLoadingMenu: false,
      isLoadingProdutos: false,
      produtos: [],
      page: (pag) ? pag : 1,
      produtosLastPage: 1,
      produtosPerPage: 20,
      produtosTotal: 1,
    }
  }

  async componentDidMount () {
    const self = this;
    self.getProdutos();
  }

  /**
   * FUNCAO PARA BUSCAR LISTA DE PRODUTOS NA API
   */
  private async getProdutos () {
    const self = this;

    self.setState({isLoadingProdutos: true});

    let param = {
      termo: self.state.paramTermo
    }

    try {
      const {data} = await self.api.post(`/product/search/${self.state.page}`, param);

      console.log(data);

      if ( !data.status ) {
        throw new Error(`Falha ao buscar produtos`);
      }

      self.setState({
        produtos: data.msg.data,
        produtosLastPage: data.msg.lastPage,
        page: data.msg.page,
        produtosPerPage: data.msg.perPage,
        produtosTotal: data.msg.total,
      });

    } catch (e) {
      console.error(e);
      self.setState({ produtos: [] });
    } finally {
      self.setState({isLoadingProdutos: false});
    }
  }

  /**
   * FAZ TRATAMENTO DO LINK PARA REDIRECIONAMENTO DE PAGINACAO
   */
  private handlePagination ( pag: number ) {
    const self = this;
    let auxPag = '';
    let link = "";

    if (( pag <= 1 ) || ( !pag ))
      auxPag = '';
    else if ( pag > self.state.produtosLastPage )
      auxPag = `?pag=${self.state.produtosLastPage}`;
    else
      auxPag = `?pag=${pag}`;
    
    window.location.href = `/busca/${self.state.paramTermo}${auxPag}`;
  }

  render () {
    return (
      <>
        <Header searchTermo={this.state.paramTermo}/>
        <section className="product-section">

          <h1>Resultados para {this.state.paramTermo}</h1>

          {/* VITRINE DE PRODUTOS */}
          {
            (this.state.isLoadingProdutos) ? 
            <p>Carregando produtos...</p>
            :
            (this.state.produtos.length <= 0) ? 
            <p>Nenhum produto encontrado.</p>
            :
            <Row>
              {
                this.state.produtos.map((row, index) => (
                  <Col lg={3} md={6}>
                    <Product 
                      imageNormal={
                        ((row.FOTOS) && (row.FOTOS.length > 0)) ? 
                        `${process.env.REACT_APP_BASE_URL_PICTURE}/${row.FOTOS[0].link}` 
                        : 
                        `${process.env.REACT_APP_BASE_URL_PICTURE}/produto-sem-imagem.png`
                      }
                      imageOver={
                        ((row.FOTOS) && (row.FOTOS.length > 0)) ? 
                          (row.FOTOS.length > 1) ? 
                          `${process.env.REACT_APP_BASE_URL_PICTURE}/${row.FOTOS[1].link}` 
                          : 
                          `${process.env.REACT_APP_BASE_URL_PICTURE}/${row.FOTOS[0].link}`
                        :
                        `${process.env.REACT_APP_BASE_URL_PICTURE}/produto-sem-imagem.png`
                      } 
                      item={row}
                    />
                  </Col>
                ))
              }
            </Row>
          }

          {/* PAGINACAO */}
          <Row>
            <Col>
              <Pagination
                activePage={this.state.page}
                itemsCountPerPage={this.state.produtosPerPage}
                totalItemsCount={this.state.produtosTotal}
                pageRangeDisplayed={5}
                onChange={this.handlePagination.bind(this)}
                itemClass="page-item"
                linkClass="page-link"
              />
            </Col>
          </Row>
         
        </section>
        <Footer/>
        
        {/* Cria modal dos filtros */}
        <Modal
        show={this.state.showModal}
        onHide={() => this.setState({showModal: false})}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        className="modal-container"
        >
          <FilterModal/>
        </Modal>
      </>
    );
  }
  
}


const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.data,
  param: state.param.data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({...UserActions, ...ParamActions}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Pesquisa);
