import React, { Component } from 'react';
import './index.scss';

import Header from '../../components/Header'
import Footer from '../../components/Footer'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Missao from '../../assets/Images/missao.png';
import Atendimento from '../../assets/Images/atendimento.png';
import Demanda from '../../assets/Images/demanda.png';
import Cadastro from '../../assets/Images/cadastro.png';
import Entrega from '../../assets/Images/entrega.png';
import logo from '../../assets/Images/logo.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';


class Frete extends Component <{}> {

  state: any = {};

  constructor (props: any) {
    super(props);
  }

  render () {
    return (
      <>

        <Header/>

        <section className="institucional">
          <h1 className="mt-3"> <span className="heading-border"> Frete e envio </span> </h1>
          <article className="sobre-nos-texto">
            <p>
              
            </p>
          </article>
          
          <Row className="sobre-lojas">
            <Col xs={12} xl={12}>
              <p>
                Na Hidroelétrica Colombo é fácil receber seu produto: <br/>
                <ul>
                  <li>O envio do seu pedido é realizado de 1 a 3 dias úteis após a compra, que é o nosso tempo interno de separação, conferência, embalagem e faturamento;</li>
                  <li><strong>Todas as entregas têm seguro contra avarias e extravio, garantimos a entrega do seu pedido em perfeitas condições;</strong></li>
                  <li>Acompanhamos o seu pedido até a entrega.</li>
                </ul>
              </p>
              <p>As transportadoras disponíveis são:</p>
              <p><strong>PAC:</strong> serviço de entrega econômica dos Correios, disponível para todo o Brasil</p>
              <p><strong>Sedex:</strong> serviço de entrega expressa dos Correios, disponível para todo o Brasil</p>
              <p><strong>Transportadora Total Express:</strong> entrega feita através de empresa de transporte terceirizada e especializada em entregas com serviço de rastreamento, disponível para algumas regiões do Brasil. Nas regiões onde esta transportadora não tem frota própria, eles transportam até a cidade mais próxima e de lá enviam pelo Sedex até o endereço indicado.</p>
              <p>A diferença entre os três serviços está no prazo de entrega, sendo normalmente o PAC Encomenda a modalidade mais econômica, o Sedex a modalidade mais ágil e a Transportadora uma entrega intermediária com ótimo custo-benefício.</p>
              <p>Para os serviços de entrega dos Correios (incluindo redespacho da transportadora), em alguns casos pode ser necessário retirar a encomenda na agência dos Correios designada caso os Correios estejam com restrição de entrega na região. Isto geralmente ocorre quando os Correios estão com alguma dificuldade operacional na região, como veículos em manutenção ou problemas logísticos. Nestes casos, a retirada da encomenda deverá ser feita pelo cliente, sendo que Hidroelétrica irá fornecer todas as informações disponíveis para auxiliar neste processo (código de rastreamento, endereço da agência, etc). É pouco comum de ocorrer, mas caso ocorra com sua encomenda, pode ficar tranquilo que vamos orientar para que o recebimento da encomenda seja fácil e tranquilo.</p>
              <p>Caso tenha alguma dificuldade em efetuar sua compra ou dúvida sobre as formas de entrega, entre em contato gratuitamente com nossa Central de Atendimento:  41 3356-3233 (WhatsApp e Telefone)</p>
              <p>Além de garantia, segurança e integridade dos produtos, disponibilizamos um sistema de rastreamento que lhe permite acompanhar os passos da entrega, através do link: <br/> <a href="http://www.correios.com.br" target="_blank">www.correios.com.br</a>.</p>
              <p>Todos os produtos de nossas lojas, são acondicionados em embalagens de alta resistência, além de uma proteção extra para transporte.</p>
            </Col>
          </Row>
        </section>

        <Footer/>

      </>
    );
  }
}

export default Frete;
