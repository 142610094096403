import React from 'react';
import { Redirect, Link } from "react-router-dom";
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import {ApplicationState} from '../../store';
import {User, UserTypes} from '../../store/ducks/user/types';
import * as UserActions from '../../store/ducks/user/actions';
import {Param, ParamTypes} from '../../store/ducks/param/types';
import * as ParamActions from '../../store/ducks/param/actions';
import {Cart, CartTypes} from '../../store/ducks/cart/types';
import * as CartActions from '../../store/ducks/cart/actions';
import {OrderType, OrderTypes, ItemType} from '../../store/ducks/order/types';
import * as OrderActions from '../../store/ducks/order/actions';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Api from '../../services/api';
import {Diversos} from '../../services/diversos';
import { Image, Row, Col, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import Lottie from 'react-lottie';
import './index.scss';
import Illustration from '../../assets/Images/logo.png';

import successGif from '../../assets/animations/success.json'

interface StateProps { 
  user: User;
  param: Param;
  cart: Cart;
  order: OrderType;
} 

interface DispatchProps {
  doLogin(user: User): void;
  setParam(param: Param): void;
  drop(rowid: string): void;
  dropOrder(): void;
}

interface OwnProps {}

type Props = StateProps & DispatchProps & OwnProps;

class NoMatchPage extends React.Component<Props> {

  state: any = null;

  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render () {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }

    return (
      <>
        <Header/>
        
        <section className="no-match-page">
          <h1 className="title-404"> A página que você está procurando não existe </h1>
          <Container className="mt-5 text-center">
            <Image src={Illustration} className="illustra-404"/>
          </Container>
          <Container className="text-center causas-container">
            <p className="causas-titulo"> Algumas possíveis causas são: </p>
            <ul className="causas">
              <li className=""> O endereço do site contenha erros de digiação </li>
              <span className="moved"> movido para outro lugar </span>
              <li>  O conteúdo desse endereço tenha sido </li> 
              <li className="never-existed"> A página nunca chegou a existir </li>
            </ul>
            <button className="back-home">
            <a href="/" className="back-home"> Voltar para a Home </a>
          </button>
          </Container>
        </section>

        <Footer/>
      </>
    );
  }
}

export default NoMatchPage;