import React, { Component } from 'react';
import './index.scss';

import Header from '../../components/Header'
import Footer from '../../components/Footer'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Missao from '../../assets/Images/missao.png';
import Atendimento from '../../assets/Images/atendimento.png';
import Demanda from '../../assets/Images/demanda.png';
import Cadastro from '../../assets/Images/cadastro.png';
import Entrega from '../../assets/Images/entrega.png';
import logo from '../../assets/Images/logo.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';


class Lojas extends Component <{}> {

  state: any = {};

  constructor (props: any) {
    super(props);
  }

  render () {
    return (
      <>

        <Header/>

        <section className="institucional">
          <h1 className="mb-5"> <span className="heading-border"> Nossas Lojas </span> </h1>
          <Row className="mt-5 sobre-lojas">
            <Col xs={12} xl={6} className="my-5">
              <div className="lojas-title">
                <FontAwesomeIcon icon={faMapMarkerAlt}/>
                <h3> Loja 1 </h3>
              </div>
              <div className="lojas-desc">
                <p>  </p>
                <p>  </p>
              </div>
              <img src={logo}/>
              <a href="#" target="_blank" className="see-on-map"> Ver no mapa </a>
            </Col>
            <Col xs={12} xl={6} className="my-5">
              <div className="lojas-title">
                <FontAwesomeIcon icon={faMapMarkerAlt}/>
                <h3> Loja 2 </h3>
              </div>
              <div className="lojas-desc">
                <p>  </p>
                <p>  </p>
              </div>
              <img src={logo}/>
              <a href="#" target="_blank" className="see-on-map"> Ver no mapa </a>
            </Col>
          </Row>
        </section>

        <Footer/>

      </>
    );
  }
}

export default Lojas;
