import React, { Component, JSXElementConstructor } from 'react';
import { Redirect, Link, useParams } from "react-router-dom";
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import { compose } from "recompose";
import moment from "moment";
import queryString from 'query-string';
import Pagination from "react-js-pagination";
// import Shimmer from 'react-js-loading-shimmer';
import Shimmer from '@bit/aju.main.shimmer';
import {Helmet} from "react-helmet";
import {ApplicationState} from '../../store';
import {User, UserTypes} from '../../store/ducks/user/types';
import * as UserActions from '../../store/ducks/user/actions';
import {Param, ParamTypes} from '../../store/ducks/param/types';
import * as ParamActions from '../../store/ducks/param/actions';
import Api from '../../services/api';
import './index.scss';
import ReactDOM from 'react-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'

import Modal from 'react-bootstrap/Modal'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import FilterModal from '../../components/FilterModal';
import Product from '../../components/Product';
import { Diversos } from '../../services/diversos';

interface RouteParams {
  menu1: string
  menu2: string
  menu3: string
}

class Departamento extends Component<{}> {

  api: any = null;
  state: any = {};

  constructor (props) {
    super(props);
    this.api = new Api();
    const {pag} = queryString.parse(props.location.search);
    const {menu1, menu2, menu3} = props.match.params;
    this.state = {
      paramMenu1: menu1,
      paramMenu2: menu2,
      paramMenu3: menu3,
      menu1: {},
      menu2: {},
      menu3: {},
      isLoadingMenu: true,
      isLoadingProdutos: true,
      produtos: [],
      page: (pag) ? pag : 1,
      produtosLastPage: 1,
      produtosPerPage: 20,
      produtosTotal: 1,
      showModal: false,
    }
  }

  async componentDidMount () {
    const self = this;
    self.handleMenu();
  }

  /**
   * FUNCAO PARA BUSCAR CONFIGURACOES DO MENU ATUAL NA API
   */
  private async handleMenu () {
    const self = this;

    let link: any = null;
    let param: any = {};

    if (( self.state.paramMenu1 ) && ( self.state.paramMenu2 ) && ( self.state.paramMenu3 )) {
      param = { menu1: self.state.paramMenu1, menu2: self.state.paramMenu2, menu3: self.state.paramMenu3 }
      link = `/menu/nivel3/${self.state.paramMenu3}`;
    } else if (( self.state.paramMenu1 ) && ( self.state.paramMenu2 ) && ( !self.state.paramMenu3 )) {
      param = { menu1: self.state.paramMenu1, menu2: self.state.paramMenu2 }
      link = `/menu/nivel2/${self.state.paramMenu2}`;
    } else if (( self.state.paramMenu1 ) && ( !self.state.paramMenu2 ) && ( !self.state.paramMenu3 )) {
      param = { menu1: self.state.paramMenu1 }
      link = `/menu/nivel1/${self.state.paramMenu1}`;
    }

    if (( !link ) || ( !param ))
      return;

    self.setState({isLoadingMenu: true});

    try {
      const {data} = await self.api.get(link);

      if ( !data.status ) {
        throw new Error(`Falha ao buscar dados do menu em ${link}`);
      }

      if ( link.indexOf('/menu/nivel1') > -1 ) {
        self.setState({
          menu1: { CDMENU: data.msg.CDMENU, DESCRICAO: data.msg.DESCRICAO },
          menu2: data.msg.menu2
        });
      } else if ( link.indexOf('/menu/nivel2') > -1 ) {
        console.log(`entrou aqui 2`);
        self.setState({
          menu1: { CDMENU: data.msg.menu1.CDMENU, DESCRICAO: data.msg.menu1.DESCRICAO },
          menu2: [{ CDMENU: data.msg.CDMENU, DESCRICAO: data.msg.DESCRICAO }]
        });
      } else if ( link.indexOf('/menu/nivel3') > -1 ) {
        console.log(`entrou aqui 3`);
        self.setState({
          menu1: { CDMENU: data.msg.menu2.menu1.CDMENU, DESCRICAO: data.msg.menu2.menu1.DESCRICAO },
          menu2: [{ CDMENU: data.msg.menu2.CDMENU, DESCRICAO: data.msg.menu2.DESCRICAO }],
          menu3: [{ CDMENU: data.msg.CDMENU, DESCRICAO: data.msg.DESCRICAO }]
        });
      }

      self.getProdutos(); // BUSCA PRODUTOS PELA PRIMEIRA VEZ

    } catch (e) {
      console.error(e);
    } finally {
      self.setState({isLoadingMenu: false});
    }
  }

  /**
   * FUNCAO PARA BUSCAR LISTA DE PRODUTOS NA API
   */
  private async getProdutos () {
    const self = this;

    self.setState({isLoadingProdutos: true});

    let param: any = {
      menu1: self.state.menu1.CDMENU
    }

    if ( self.state.paramMenu2 )
      param.menu2 = self.state.menu2[0].CDMENU;

    if ( self.state.paramMenu3 )
      param.menu3 = self.state.menu3[0].CDMENU;

    try {
      const {data} = await self.api.post(`/product/departamento/${self.state.page}`, param);

      if ( !data.status ) {
        throw new Error(`Falha ao buscar produtos`);
      }

      self.setState({
        produtos: data.msg.data,
        produtosLastPage: data.msg.lastPage,
        page: data.msg.page,
        produtosPerPage: data.msg.perPage,
        produtosTotal: data.msg.total,
      });

    } catch (e) {
      console.error(e);
      self.setState({ produtos: [] });
    } finally {
      self.setState({isLoadingProdutos: false});
    }
  }

  /**
   * FAZ TRATAMENTO DO LINK PARA REDIRECIONAMENTO DE PAGINACAO
   */
  private handlePagination ( pag: number ) {
    const self = this;
    let auxPag = '';
    let link = "";

    if (( pag <= 1 ) || ( !pag ))
      auxPag = '';
    else if ( pag > self.state.produtosLastPage )
      auxPag = `?pag=${self.state.produtosLastPage}`;
    else
      auxPag = `?pag=${pag}`;
    
    if (( self.state.paramMenu1 ) && ( self.state.paramMenu2 ) && ( self.state.paramMenu3 ))
      link = `/departamento/${self.state.paramMenu1}/${self.state.paramMenu2}/${self.state.paramMenu3}${auxPag}`;
    else if (( self.state.paramMenu1 ) && ( self.state.paramMenu2 ) && ( !self.state.paramMenu3 ))
      link = `/departamento/${self.state.paramMenu1}/${self.state.paramMenu2}${auxPag}`;
    else
      link = `/departamento/${self.state.paramMenu1}${auxPag}`;
    
    window.location.href = link;
  }

  private _henderSEOTags () {
    if (( this.state.isLoading ) || ( this.state.isLoadingMenu )) {
      return (<></>);
    } else {

      let title = `${Diversos.capitalize(this.state.menu1.DESCRICAO)} - ${process.env.REACT_APP_TITLE}`;
      let url = `${process.env.REACT_APP_URL}/departamento/${this.state.paramMenu1}`;
      let description = `Melhores ofertas de ${Diversos.capitalize(this.state.menu1.DESCRICAO)} - ${process.env.REACT_APP_TITLE}`;

      if (( this.state.paramMenu1 ) && ( this.state.paramMenu2 ) && ( this.state.paramMenu3 )) {
        title = `${Diversos.capitalize(this.state.menu1.DESCRICAO)} / ${Diversos.capitalize(this.state.menu2[0].DESCRICAO)} / ${Diversos.capitalize(this.state.menu3[0].DESCRICAO)} - ${process.env.REACT_APP_TITLE}`;
        description = `Melhores ofertas de ${Diversos.capitalize(this.state.menu1.DESCRICAO)} / ${Diversos.capitalize(this.state.menu2[0].DESCRICAO)} / ${Diversos.capitalize(this.state.menu3[0].DESCRICAO)} - ${process.env.REACT_APP_TITLE}`;
        url = `${process.env.REACT_APP_URL}/departamento/${this.state.paramMenu1}/${this.state.paramMenu2}/${this.state.paramMenu3}`;

      } else if (( this.state.paramMenu1 ) && ( this.state.paramMenu2 ) && ( !this.state.paramMenu3 )) {
        title = `${Diversos.capitalize(this.state.menu1.DESCRICAO)} / ${Diversos.capitalize(this.state.menu2.DESCRICAO)} - ${process.env.REACT_APP_TITLE}`;
        description = `Melhores ofertas de ${Diversos.capitalize(this.state.menu1.DESCRICAO)} / ${Diversos.capitalize(this.state.menu2[0].DESCRICAO)} - ${process.env.REACT_APP_TITLE}`;
        url = `${process.env.REACT_APP_URL}/departamento/${this.state.paramMenu1}/${this.state.paramMenu2}`;
      }

      return (
        <Helmet>
          <title>{title}</title>
          <link rel="canonical" href={url} />
          <meta name="url" content={url} />
          <meta name="robots" content="index"/>
          <meta name="description" content={description} />
          <meta name="autor" content="TecWorks" />
          <meta name="company" content="TecWorks" />
          <meta name="revisit-after" content="2" />

          {/* TWITTER CARDS */}
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:site" content={url} />
          <meta name="twitter:title" content={title} />
          <meta name="twitter:description" content={description} />

          {/* FACEBOOK CARDS */}
          <meta property="fb:app_id" content="270229570743012" />
          <meta property="og:site_name" content={title}/>
          <meta property="og:locale" content="pt_BR" />
          <meta property="og:type" content="website"/>
          <meta property="og:url" content={url}/>
          <meta property="og:title" content={title}/>
          <meta property="og:description" content={description}/>
        </Helmet>
      );
    }
  }

  render () {
    return (
      <>
        {this._henderSEOTags()}
        <Header/>
        <section className="products-display">


          <Row className="section-title">
            {
              (this.state.isLoadingMenu) ? 
                <p className="loading-content"> Carregando... </p>
              :
                <>
                  {/* TITULO DA PAGINA */}
                  <Col lg={7} xl={8}>
                    <h1 className="mt-5 mb-5"> 
                      {this.state.menu1.DESCRICAO} 
                      {
                        this.state.paramMenu2 &&
                        ` / ${this.state.menu2[0].DESCRICAO}`
                      }
                      {
                        this.state.paramMenu3 &&
                        ` / ${this.state.menu3[0].DESCRICAO}`
                      }
                    </h1>
                  </Col>

                  <Col lg={5} xl={4}>
                    <Row className="filters-container">
                      <Col xs={12} sm={2} md={2} lg={3} className="m-auto">
                      {/* <p className="filters"> Filtros </p> */}
                        <Button variant="outline-primary" className="filters" onClick={() => this.setState({showModal: true})}> Filtros </Button>
                      </Col>
                      <Col xs={12} sm={10} md={10} lg={9} className="m-auto d-flex align-items-center">
                        <Form className="ipp-form">
                          <Form.Group  className="ipp-form-group" controlId="items-per-page">
                            <Form.Control as="select">
                              <option> mostrar 20 itens por página </option>
                              <option> mostrar 30 itens por página </option>
                              <option> mostrar 40 itens por página </option>
                            </Form.Control> 
                          </Form.Group>
                        </Form>
                      </Col>
                    </Row>
                  </Col>
                </> 
            }
          </Row>

          {/* VITRINE DE PRODUTOS */}
          {
            (this.state.isLoadingProdutos) ? 
            <Row>
              {
                [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18].map((row, index) => (
                  <Col xs={12} sm={6} md={4} lg={3} key={index} className="d-flex justify-content-center my-3" id="product-col" style={{height: 250}}>
                    <div className="product-container">
                      <Row className="w-100 img-wrapper">
                        <div className="mx-auto" style={{width: '90%'}}>
                          <Shimmer width="100%" height="100px"/>
                        </div>
                      </Row>
                      <Row className="w-100 product-info">
                        <Row className="product-name">
                          <Shimmer width="100%" height="100%" />
                        </Row>
                      </Row>
                    </div>
                  </Col>
                ))
              }
            </Row>
            :
            (this.state.produtos.length <= 0) ? 
            <p> Nenhum produto encontrado. </p>
            :
            <Row>
              {
                this.state.produtos.map((row, index) => (
                  <Col xs={12} sm={6} md={4} lg={3} key={index} className="d-flex justify-content-center" id="product-col">
                    <Product 
                      imageNormal={
                        ((row.FOTOS) && (row.FOTOS.length > 0)) ? 
                        `${process.env.REACT_APP_BASE_URL_PICTURE}/${row.FOTOS[0].link}` 
                        : 
                        `${process.env.REACT_APP_BASE_URL_PICTURE}/produto-sem-imagem.png`
                      }
                      imageOver={
                        ((row.FOTOS) && (row.FOTOS.length > 0)) ? 
                          (row.FOTOS.length > 1) ? 
                          `${process.env.REACT_APP_BASE_URL_PICTURE}/${row.FOTOS[1].link}` 
                          : 
                          `${process.env.REACT_APP_BASE_URL_PICTURE}/${row.FOTOS[0].link}`
                        :
                        `${process.env.REACT_APP_BASE_URL_PICTURE}/produto-sem-imagem.png`
                      } 
                      item={row}
                    />
                  </Col>
                ))
              }
            </Row>
          }

          {/* PAGINACAO */}
          <Row>
            <Col className="mt-4 mb-5 d-flex justify-content-center">
              <Pagination
                activePage={this.state.page}
                itemsCountPerPage={this.state.produtosPerPage}
                totalItemsCount={this.state.produtosTotal}
                pageRangeDisplayed={5}
                onChange={this.handlePagination.bind(this)}
                itemClass="page-item"
                linkClass="page-link"
              />
            </Col>
          </Row>
         
        </section>
        <Footer/>
        
        {/* Cria modal dos filtros */}
        <Modal
          show={this.state.showModal}
          onHide={() => this.setState({showModal: false})}
          dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title"
          className="modal-container">
          <FilterModal/>
        </Modal>
      </>
    );
  }
  
}


const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.data,
  param: state.param.data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({...UserActions, ...ParamActions}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Departamento);
