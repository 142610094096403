import React, { Component } from 'react';
import queryString from 'query-string';
import Api from '../../services/api';
import './index.scss';

// import de Componentes do Bootstrap
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import Container from 'react-bootstrap/Container'
import Modal from 'react-bootstrap/Modal'
// import RangeSlider from 'react-bootstrap-range-slider';

const documentWidth = document.documentElement.clientWidth;
const windowWidth = window.innerWidth;
const scrollBarWidth = windowWidth - documentWidth;

const preventContentJump = document.getElementsByClassName('modal-open');


const PRICES = [
  {value: '0-50', label: 'Até R$50,00'},
  {value: '50-100', label: 'De R$50,00 a R$100,00'},
  {value: '100-200', label: 'De R$100,00 a R$200,00'},
  {value: '200-'+Number.MAX_SAFE_INTEGER, label: 'Mais de R$200,00'}
]

// const BRANDS = [
//   {id: 0, title: 'Amigurumi'},
//   {id: 1, title: 'Macramê'},
//   {id: 2, title: 'Euroroma'},
//   {id: 3, title: 'Singer'},
//   {id: 4, title: 'NYBC'},
//   {id: 5, title: 'Jumbo'},
//   {id: 6, title: 'Liza'},
//   {id: 7, title: 'Fina'},
//   {id: 8, title: 'Cisne'}
// ]


class TooltipBehaviour extends Component {

  state: any = null;

  constructor(props) {
    super(props);
    this.state = {
      value: 0
    }
    this.setValue = this.setValue.bind(this);
  }

  setValue(value) {
    this.setState({ value });
  }

  handleChange() {
    
  }

  render () {
    return (
      <>
        <Form className="form-range-container">
          <Form.Group as={Row}>
            <Col className="range-container">
              {/* <RangeSlider
                value={this.state.value}
                onChange={e => this.setValue(e.target.value)}
                min="0"
                max="500"
                step="10"
                tooltip='on'
                tooltipPlacement="top"
                variant="primary"
              />         */}
            </Col>
          </Form.Group>
        </Form>
      </>
    );
  }
}

type Props = any;

class FilterModal extends Component<Props> {

  state: any;
  api: any = null;

  constructor(props){
    super(props);
    this.api = new Api();

    const {marcas, preco} = queryString.parse(window.location.search);

    this.state = {
      brands: [],
      brandValues: (!marcas) ? [] : marcas,
      priceValue: (!preco) ? null : preco,
      priceMin: (!preco) ? null : preco.toString().split("-")[0],
      priceMax: (!preco) ? null : preco.toString().split("-")[1],
      isLoadingMarcas: false
    }

    // this.setBtnVariant = this.setBtnVariant.bind(this);
  }

  componentDidMount () {
    this.getMarcas();
  }

  /**
   * FUNCAO PARA BUSCAR MARCAS DE PRODUTOS NA API
   */
  private async getMarcas () {
    const self = this;

    self.setState({isLoadingMarcas: true});

    let param = {
    }

    try {
      const {data} = await self.api.post(`/product/marcas`, param);

      if ( !data.status ) {
        throw new Error(`Falha ao buscar marcas de produtos`);
      }

      self.setState({
        brands: data.msg
      });

    } catch (e) {
      console.error(e);
      self.setState({ brands: [] });
    } finally {
      self.setState({isLoadingMarcas: false});
    }
  }

  private handleFiltros () {
    const self = this;
    let link = window.location.pathname;
    const param = queryString.parse(window.location.search);
    const brands = self.state.brandValues;
    let price = self.state.priceValue;

    if (( self.state.priceMin ) || ( self.state.priceMax ))
      price = `${parseInt(self.state.priceMin)}-${parseInt(self.state.priceMax)}`;

    let newsParam = param;

    if ( param.pag )
      newsParam = {...newsParam, pag: "1"};
    else
      delete newsParam.pag;

    if ( brands )
      newsParam = {...newsParam, marcas: brands};
    else
      delete newsParam.marcas;

    if ( price )
      newsParam = {...newsParam, preco: price};
    else
      delete newsParam.preco;

    window.location.href = queryString.stringifyUrl({url: link, query: newsParam});
  }

  private handleBrandFilter = button => {

    let tmp = this.state.brandValues;

    if (( !tmp ) || ( !Array.isArray(tmp) ))
      tmp = [];

    if (this.state.brandValues.includes(button)) {
      this.setState({
        brandValues: this.state.brandValues.filter(el => el !== button)
      })
    }

    else {
      tmp.push(button);

      this.setState({
        brandValues: tmp
      })
    }
  }

  private handlePriceFilter = ev => {

    if (this.state.priceValue === (ev)) {
      this.setState({
        priceValue: null
      })
    }

    else {
      this.setState({priceValue: ev});
    }
  }

  
  render () {
    return (
      <>
        <Modal.Header closeButton className="filter-header">
          <Modal.Title className="filter-title">
            Filtros
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="filter-type">
            <h2 className="my-5"> <span className="heading-border"> Ordenar por </span> </h2>
          </div>
          <div className="filter-type">
            <p className="filter-label"> Preço </p>
            <Col className="filter-price mb-3">
              {
                PRICES.map(key => 
                  <button
                    key={key.value}
                    onClick={() => this.handlePriceFilter(key.value)}
                    id="font-size-09"
                    className={key.value === this.state.priceValue ? "btn-pressed" : "btn-brand-action"}>
                    {key.label}
                  </button>
                )
              }
            </Col>
            <Row className="d-flex justify-content-center">
              <Col xs="5" sm="3" lg="2">
                <InputGroup size="sm" className="mb-3">
                  <InputGroup.Prepend>
                    <InputGroup.Text className="input-group-price"> R$ </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl aria-label="Small" aria-describedby="inputGroup-sizing-sm" placeholder="Min" value={this.state.priceMin} onChange={(event) => this.setState({priceMin: event.target.value})}/>
                </InputGroup>
              </Col>
              <Col xs="5" sm="3" lg="2">
                <InputGroup size="sm" className="mb-3">
                  <InputGroup.Prepend>
                    <InputGroup.Text className="input-group-price"> R$ </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl aria-label="Small" aria-describedby="inputGroup-sizing-sm" placeholder="Max" value={this.state.priceMax} onChange={(event) => this.setState({priceMax: event.target.value})}/>
                </InputGroup>
              </Col>
            </Row>
          </div>
          <div className="filter-type">
            <p className="filter-label"> Marcas </p>
            <Container className="filter-brand">
              <Row>
              <Col xs="auto">
                {
                  this.state.isLoadingMarcas ? 
                  <i className="fas fa-spin fa-spinner"></i>
                  :
                  this.state.brands.map(btn => (
                    <button 
                      key={btn.MARCA} 
                      onClick={() => this.handleBrandFilter(btn.MARCA)}
                      className={this.state.brandValues.includes(btn.MARCA) ? "btn-pressed" : "btn-brand-action"}>
                      {btn.MARCA}
                    </button>
                  ))
                }
              </Col>
              </Row>
            </Container>
            <hr/>
          </div>
          <span className="filter-action">
            <Button className="mb-3 btn-primary-action" onClick={this.handleFiltros.bind(this)}> Aplicar filtros </Button> 
            <Button variant="link" className="btn-secondary-action" onClick={() => this.setState({brandValues: [], priceValue: null, priceMin: null, priceMax: null}, this.handleFiltros.bind(this)) }> Resetar filtros </Button> 
          </span>
        </Modal.Body>
      </>
    );
  }
}

export default FilterModal;
