import React, { Component } from 'react';
import './index.scss';

import Header from '../../components/Header'
import Footer from '../../components/Footer'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Missao from '../../assets/Images/missao.png';
import Atendimento from '../../assets/Images/atendimento.png';
import Demanda from '../../assets/Images/demanda.png';
import Cadastro from '../../assets/Images/cadastro.png';
import Entrega from '../../assets/Images/entrega.png';
import logo from '../../assets/Images/logo.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';


class Empresa extends Component <{}> {

  state: any = {};

  constructor (props: any) {
    super(props);
  }

  render () {
    return (
      <>

        <Header/>

        <section className="institucional">
          <h1 className="mt-3"> <span className="heading-border"> A Hidroelétrica Colombo </span> </h1>
          <article className="sobre-nos-texto">
            <p>
              
            </p>
          </article>
          
          <Row className="sobre-lojas">
            <Col xs={12} xl={12}>
              <div className="lojas-title">
                {/* <FontAwesomeIcon icon={faMapMarkerAlt}/> */}
                <h3> Quem somos </h3>
              </div>
              {/* <img src={logo}/> */}
              <p>A Hidroelétrica Colombo é uma loja completa de produtos elétricos, hidráulicos, ferragens, tintas e utilidades.</p>
              <p>Trabalhamos com produtos exclusivos e com as melhores marcas do mercado, propiciando soluções que atendem as necessidades de nossos clientes.</p>
              <p>Desde 1996 no mercado, nosso foco é a excelência no atendimento, com uma estrutura sólida e uma equipe treinada, formada por profissionais de administração, marketing, compras e vendas.</p>
              <p>O slogan da HC - A melhor opção para sua casa, envolve não só o comprometimento com a qualidade dos produtos. O atendimento oferecido pelas lojas é qualificado, oferecendo soluções, dicas e esclarecimento sobre indicação, instalação e utilização de cada item disponível nas lojas.</p>
              <p>Somos uma empresa capaz de fornecer soluções atendendo as necessidades de reparo, acessórios, produtos e utilidades de acordo com a particularidade de cada situação.</p>
              <p>Por isso, um dos diferenciais da HC é ir além dos serviços pontuais e pensar estrategicamente, em oferecer produtos que além de atender gerem uma excelente experiência de compra.</p>
            </Col>
          </Row>
        </section>

        <Footer/>

      </>
    );
  }
}

export default Empresa;
