import React from 'react';
import './index.scss';
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import {ApplicationState} from '../../store';
import {User, UserTypes} from '../../store/ducks/user/types';
import * as UserActions from '../../store/ducks/user/actions';
import {Param, ParamTypes} from '../../store/ducks/param/types';
import * as ParamActions from '../../store/ducks/param/actions';
import { Redirect, Link } from "react-router-dom";
import queryString from 'query-string';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Api from '../../services/api';
import {Diversos} from '../../services/diversos';

import InputMask from 'react-input-mask'
import Row from 'react-bootstrap/Row'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import {Spinner} from 'react-bootstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { findDOMNode } from 'react-dom';
import { createJsxSelfClosingElement } from 'typescript';


interface StateProps { 
  user: User;
}

interface DispatchProps {
  doLogin(user: User): void;
  doLogout(): void;
}

interface OwnProps {}

type Props = StateProps & DispatchProps & OwnProps;

// const { password } = this.state;

class Cadastro extends React.Component<Props> {

  api: any = null;
  state: any = null;

  novoCepRef: any = null;
  novoRuaRef: any = null;
  novoNumeroRef: any = null;
  novoBairroRef: any = null;
  novoCidadeRef: any = null;
  novoUfRef: any = null;
  novoComplementoRef: any = null;

  constructor(props) {
    super(props);

    this.api = new Api();

    const {endereco} = queryString.parse(props.location.search);
    const { back } = queryString.parse(window.location.search);

    this.novoCepRef = React.createRef();
    this.novoRuaRef = React.createRef();
    this.novoNumeroRef = React.createRef();
    this.novoBairroRef = React.createRef();
    this.novoCidadeRef = React.createRef();
    this.novoUfRef = React.createRef();
    this.novoComplementoRef = React.createRef();

    this.state = {
      redirect: null,

      hasEnderecoAltera: (!endereco) ? false : true,
      
      // VARIAVEIS DE CONTROLE PARA FORM DE NOVO CADASTRO
      isLoadingNovo: false,
      novoNome: null,
      novoCpf: null,
      novoTelefone: null,
      novoEmail: null,
      novoSenha: null,
      novoConfSenha: null,
      novoCep: null,
      novoRua: null,
      novoNumero: null,
      novoBairro: null,
      novoCidade: null,
      novoEstado: null,
      novoComplemento: null,
      novoCodmun: null,
      novoHasError: false,
      novoHasErrorTitle: null,
      novoHasErrorMsg: null,
      currentStep: 1,
      email:  '',
      username: '',
      password: '', 

      // VARIAVEIS DE CONTROLE PARA FORM DE CADASTRO JA EXISTENTE
      isLoadingCustomer: true,
      customer: null,
      customerHasError: false,
      customerHasErrorTitle: null,
      customerHasErrorMsg: null,
      customerHasSuccess: false,
      customerHasSuccessTitle: null,
      customerHasSuccessMsg: null,
      // customer: {
      //   nome: "a",
      //   email: "a@a.com",
      //   cpf: "13",
      //   nascimento: "08",
      //   sexo: "M",
      //   celular: "1231",
      //   telefone: "12312",
      //   cep: "12312312",
      //   rua: "1231231",
      //   numero: "12312",
      //   complemento: "12312",
      //   bairro: "12312",
      //   cidade: "1231",
      //   estado: "1231",
      // },

      isLoadingCep: false,
      back: back
    }
  }

  componentDidMount () {
    const self = this;

    if (( self.props.user ) && ( self.props.user.status !== false )) { 
      self.getCustomer();
    }
  }

  stepStyling (step) {
    if (step === this.state.currentStep) {
      return 'step-active';
    }
    else if (step < this.state.currentStep) {
      return 'step-passed';
    }
    return 'step-inactive';
  }

  private async handleLogout () {
    const self = this;
    self.props.doLogout();
    self.setState({redirect: "/"});
  }

  private async getCustomer () {
    const self = this;

    self.setState({isLoadingCustomer: true});

    try {
      const {data} = await self.api.get(`/customer/${self.props.user.codigo}`);

      if ( data.status == false ) {
        throw new Error('Cadastro de cliente não localizado.');
      } else {
        self.setState({customer: data.msg});
      }

    } catch (e) {
      console.error(e);

      self.setState({
        customer: null,
        customerHasError: true,
        customerHasErrorTitle: 'Cadastro não localizado',
        customerHasErrorMsg: e.message
      });

    } finally {
      self.setState({isLoadingCustomer: false});
    }
  }

  private async getAddressByCep () {
    const self = this;

    let param = {};

    if ((self.props.user) && (self.props.user.status === true)) {
      if ( !self.state.customer.cep )
        return false;   

      param = {cep : self.state.customer.cep}
    } else {
      if ( !self.state.novoCep )
        return false;   

      param = {cep : self.state.novoCep}
    }

    self.setState({isLoadingCep: true});

    try {
      const {data} = await self.api.post('/shipping/cep', param);

      if ( data.status ) {
        self.setState({
          customer: {
            ...self.state.customer,
            rua: data.msg.logradouro,
            bairro: data.msg.bairro,
            cidade: data.msg.localidade,
            estado: data.msg.uf,
            codmun: data.msg.ibge
          },
          novoRua: data.msg.logradouro,
          novoBairro: data.msg.bairro,
          novoCidade: data.msg.localidade,
          novoEstado: data.msg.uf,
          novoCodmun: data.msg.ibge
        });
      }

    } catch (e) {
      console.error(`ERROR: /shipping/cep: ${e}}`);
    } finally {
      self.setState({isLoadingCep: false});
    }
  }

  /**
   * FUNCAO PARA TRATAR EDICAO DE CADASTRO
   */
  private async handleSubmit ( tab: number = 0 ) {
    const self = this;
    let link: string = `/customer/${self.props.user.codigo}`;
    let param: any = {};

    if ((tab < 1) || (tab > 3))
      return false;

    if ( tab === 1 ) {
      if (( !self.state.customer.email ) || ( !self.state.customer.nome ) || ( !self.state.customer.cpf ) || ( !self.state.customer.celular )) {
        self.setMsg( 'error', "Formulário incometo", "Necessário preencher todos os campos obrigatórios (*) do formulário para continuar.", 1);
        return;
      }

      if ( !Diversos.validateCPF(self.state.customer.cpf) ) {
        self.setMsg( 'error', "CPF inválido", "Necessário informar um CPF válido para continuar.", 1);
        return;
      }

      if ( !Diversos.validateEmail(self.state.customer.email) ) {
        self.setMsg( 'error', "Email inválido", "Necessário informe um email válido para continuar.", 1);
        return;
      }

      param.nome = self.state.customer.nome;
      param.email = self.state.customer.email;
      param.cpf = self.state.customer.cpf;
      param.nascimento = self.state.customer.nascimento;
      param.sexo = self.state.customer.sexo;
      param.celular = self.state.customer.celular;
      param.telefone = self.state.customer.telefone;
    }

    if ( tab === 2 ) {
      if (( !self.state.customer.cep ) || ( !self.state.customer.rua ) || ( !self.state.customer.numero ) || ( !self.state.customer.bairro ) || ( !self.state.customer.cidade ) || ( !self.state.customer.estado )) {
        self.setMsg( 'error', "Formulário incompleto", "Necessário preencher todos os campos obrigatórios (*) do formulário para continuar.", 1);
        return;
      }

      param.cep = self.state.customer.cep;
      param.rua = self.state.customer.rua;
      param.numero = self.state.customer.numero;
      param.complemento = self.state.customer.complemento;
      param.bairro = self.state.customer.bairro;
      param.cidade = self.state.customer.cidade;
      param.estado = self.state.customer.estado;
      param.codmun = self.state.customer.codmun;
    }

    if ( tab === 3 ) {
      if (( !self.state.senhaAtual ) || ( !self.state.senhaNova ) || ( !self.state.confSenhaNova )) {
        self.setMsg( 'error', "Formulário incompleto", "Necessário preencher todos os campos obrigatórios (*) do formulário para continuar.", 1);
        return;
      }

      if ( self.state.senhaNova !== self.state.confSenhaNova ) {
        self.setMsg( 'error', "Confirmação da senha inválida", "Senha nova e sua confirmação não são iguais.", 1);
        return;
      }

      param.senha = self.state.senhaAtual;
      param.senhaNova = self.state.senhaNova;
      link = `/customer/${self.props.user.codigo}/troca-senha`;
    }

    self.setState({ isLoading: true });

    try {
      const {data} = await self.api.put(link, param);

      if (!data.status) {
        throw new Error(data.msg);
      } else {
        self.setMsg( 'success', "Sucesso", `Cadastro atualizado com sucesso.`, 1);

        if (this.state.back) 
          window.history.back();
      }

    } catch (e) {
      console.error(e);
      self.setMsg( 'error', "Atenção", `Não foi possível atualizar cadastro. ${e.message}`, 1);
    } finally {
      self.setState({ isLoading: false });
    }
  }

  /**
   * FUNCAO PARA TRATAR INCLUSAO DE CADASTRO
   */
  private async handleSubmitNovo (pressedButton) {
    const self = this;
      
    if (this.state.currentStep === 1) {

      if (( !self.state.novoEmail ) || ( !self.state.novoSenha ) || ( !self.state.novoConfSenha )) {
        self.setMsg( 'error', "Formulário incompleto", `Necessário preencher todos os campos obrigatórios (*) do formulário para continuar.`, 2);
        return;
      } 

      if ( self.state.novoSenha !== self.state.novoConfSenha ) {
        self.setMsg( 'error', "Confirmação da senha", `A confirmação da senha não confere com a senha informada.`, 2);
        return;
      }

      this.setState({novoHasError: false, currentStep: 2});
      return;
    }
    
    if (this.state.currentStep === 2) {

      if (pressedButton === 1) {
        return;
      }

      if (( !self.state.novoNome ) || ( !self.state.novoCpf) || ( !self.state.novoCelular ) || ( !self.state.novoNascimento )) {
        self.setMsg( 'error', "Formulário incompleto", `Necessário preencher todos os campos obrigatórios (*) do formulário para continuar.`, 2);
        return;
      }

      if ( !Diversos.validateCPF(self.state.novoCpf) ) {
        self.setMsg( 'error', "CPF inválido", `Necessário informar um CPF válido para continuar.`, 2);
      }

      this.setState({novoHasError: false, currentStep: 3});
      return;
    }

    if (this.state.currentStep === 3) {

      if (pressedButton === 1) {
        return;
      } 

      if (( !self.state.novoCep ) || ( !self.state.novoRua ) ||
        ( !self.state.novoNumero ) || ( !self.state.novoBairro ) ||
        ( !self.state.novoCidade ) || ( !self.state.novoEstado ) ) {

        self.setMsg( 'error', "Formulário incompleto", `Necessário preencher todos os campos obrigatórios (*) do formulário para continuar.`, 2);
        return;
      }
    }

    self.setState({ novoHasError: false, isLoadingNovo: true });

    let param = {
      email: self.state.novoEmail,
      senha: self.state.novoSenha,
      nome: self.state.novoNome,
      cpf: self.state.novoCpf,
      nascimento: self.state.novoNascimento,
      telefone: self.state.novoTelefone,
      celular: self.state.novoCelular,
      cep: self.state.novoCep,
      rua: self.state.novoRua,
      numero: self.state.novoNumero,
      bairro: self.state.novoBairro,
      cidade: self.state.novoCidade,
      estado: self.state.novoEstado,
      complemento: self.state.novoComplemento,
      codmun: self.state.novoCodmun
    }

    try {
      const {data} = await self.api.post('/customer', param);

      if (!data.status) {
        throw new Error(data.msg);
      } else {
        self.props.doLogin({
          codigo: data.msg.codigo,
          nome: data.msg.nome,
          email: data.msg.email,
          cpf: data.msg.cpf,
          status: true,
          avatar: '',
          token: null,
        });

        setTimeout(() => {
          self.setState({
            redirect: '/',
          });
        }, 400)
      }

    } catch (e) {
      console.error(e);
      self.setMsg( 'error', "Atenção", `Não foi possível criar conta. ${e.message}`, 2);
    } finally {
      self.setState({ isLoadingNovo: false });
    }
  }

  /**
   * FUNCAO PARA SETAR ALERT DE ERROR OU SUCESSO COM TEMPORIZADOR
   * @param type 
   * @param title 
   * @param msg 
   * @param form 
   */
  private async setMsg ( type: string, title: string, msg: string, form: number ) {
    const self = this;
    let timeout = 5000;

    if ( type === "error" ) {
      if ( form === 1 ) {
        self.setState({
          customerHasError: true,
          customerHasErrorTitle: title,
          customerHasErrorMsg: msg,
        }, () => {
          setTimeout(() => self.setState({ customerHasError: false }), timeout)
        });
      } else {
        self.setState({
          novoHasError: true,
          novoHasErrorTitle: title,
          novoHasErrorMsg: msg,
        }, () => {
          setTimeout(() => self.setState({ novoHasError: false }), timeout)
        });
      }
    } else {
      if ( form === 1 ) {
        self.setState({
          customerHasSuccess: true,
          customerHasSuccessTitle: title,
          customerHasSuccessMsg: msg,
        }, () => {
          setTimeout(() => self.setState({ customerHasSuccess: false }), timeout)
        });
      } else {
        self.setState({
          novoHasSuccess: true,
          novoHasSuccessTitle: title,
          novoHasSuccessMsg: msg,
        }, () => {
          setTimeout(() => self.setState({ novoHasSuccess: false }), timeout)
        });
      }
    }
  }

  render () {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }

    return (
        <>
        <Header/>

        <section className="login-container" id="unlogged-user-login">
          {
            ((this.props.user) && (this.props.user.status === true)) ?
            <div className="meu-cadastro">
              <div className="title-container">
                <h1>Meu Cadastro</h1>
                <p>Todos os campos com * são obrigatórios</p>
              </div>
              <div className="tab-container">
                {
                  this.state.customerHasSuccess &&
                  <div className="alert alert-success">
                    <strong>{this.state.customerHasSuccessTitle}</strong>
                    <br/>
                    {this.state.customerHasSuccessMsg}
                  </div>
                }

                {
                  this.state.customerHasError &&
                  <div className="alert alert-danger">
                    <strong>{this.state.customerHasErrorTitle}</strong>
                    <br/>
                    {this.state.customerHasErrorMsg}
                  </div>
                }

                <Tabs defaultActiveKey={this.state.hasEnderecoAltera ? "endereço" : "pessoal"} id="controlled-tab" className="tab-controller">

                  {/* FORM PARA DADOS PESSOAIS */}
                  <Tab eventKey="pessoal" title="Dados Pessoais" className="tab-content">
                    {
                      this.state.isLoadingCustomer ? 
                      <Spinner animation="border" role="status" variant="light" size="sm">
                        <span className="sr-only">Loading...</span>
                      </Spinner>
                      :
                      <>
                        <Form className="d-flex w-100">
                          <Col>
                            <Form.Row>
                              <Form.Group as={Col} controlId="formGridCity">
                                <Form.Label className="meu-cadastro-label"> Nome * </Form.Label>
                                <Form.Control className="user-input" defaultValue={this.state.customer.nome}/>
                              </Form.Group>
                            </Form.Row>
                            <Form.Row>
                              <Form.Group as={Col} controlId="formGridCPF">
                                <Form.Label className="meu-cadastro-label"> CPF * </Form.Label>
                                <InputMask mask="999.999.999-99" alwaysShowMask={false} value={this.state.customer.cpf} onChange={(event) => this.setState({customer: {...this.state.customer, cpf: event.target.value}})}>
                                  {(inputProps) => <Form.Control {...inputProps} type="tel" className="user-input" required/>}
                                </InputMask>
                              </Form.Group>
                            </Form.Row>
                            <Form.Row>
                              <Form.Group as={Col} controlId="formGridSexo">
                                <Form.Label className="meu-cadastro-label"> Sexo </Form.Label>
                                <Form.Control as="select" className="user-input" value={this.state.customer.sexo} onChange={(event) => this.setState({customer: {...this.state.customer, sexo: event.target.value}})}>
                                  <option value={999}>...</option>
                                  <option value={1}>Feminino</option>
                                  <option value={2}>Masculino</option>
                                </Form.Control>
                              </Form.Group>
                              <Form.Group as={Col} controlId="formGridNascimento">
                                <Form.Label className="meu-cadastro-label"> Data de Nascimento * </Form.Label>
                                <InputMask mask="99/99/9999" alwaysShowMask={false} value={this.state.customer.nascimento} onChange={(event) => this.setState({customer: {...this.state.customer, nascimento: event.target.value}})}>
                                  {(inputProps) => <Form.Control {...inputProps} type="tel" className="user-input" required/>}
                                </InputMask>
                              </Form.Group>
                            </Form.Row>
                            <Form.Row>
                              <Form.Group as={Col} controlId="formGridTelefon1" xs={12} sm={6}>
                                <Form.Label className="meu-cadastro-label"> Celular * </Form.Label>
                                <InputMask mask="(99) 9 9999-9999" value={this.state.customer.celular} onChange={(event) => this.setState({customer: {...this.state.customer, celular: event.target.value}})}>
                                  {(inputProps) => <Form.Control {...inputProps} type="tel" className="user-input" required/>}
                                </InputMask>
                              </Form.Group>
                              <Form.Group as={Col} controlId="formGridTelefone2" xs={12} sm={6}>
                                <Form.Label className="meu-cadastro-label"> Telefone </Form.Label>
                                <InputMask mask="(99) 9 9999-9999" value={this.state.customer.telefone} onChange={(event) => this.setState({customer: {...this.state.customer, telefone: event.target.value}})}>
                                  {(inputProps) => <Form.Control {...inputProps} type="tel" className="user-input" required/>}
                                </InputMask>
                              </Form.Group>
                            </Form.Row>
                            <Form.Row>
                              <Form.Group as={Col} controlId="formGridEmail">
                                <Form.Label className="meu-cadastro-label"> Email </Form.Label>
                                <Form.Control type="email" className="user-input" value={this.state.customer.email} onChange={(event) => this.setState({customer: {...this.state.customer, email: event.target.value}})}/>
                              </Form.Group>
                            </Form.Row>
                          </Col>
                        </Form>

                        <div className="user-actions">
                          <Button className="primary-action" type="button" onClick={this.handleSubmit.bind(this, 1)} disabled={this.state.isLoading}>
                            {(this.state.isLoading) ? 'Salvando...' : 'Salvar'}
                          </Button>
                          {/* <a className="logout-anchor" onClick={this.handleLogout.bind(this)}>
                            Logout
                          </a> */}
                        </div>
                      </>
                    }
                  </Tab>

                  {/* FORM ENDERECO */}
                  <Tab eventKey="endereço" title="Dados de Entrega" className="tab-content">
                    {
                      this.state.isLoadingCustomer ? 
                      <Spinner animation="border" role="status" variant="light" size="sm">
                        <span className="sr-only">Loading...</span>
                      </Spinner>
                      :
                      <>
                        <Form className="d-flex w-100">
                          <Col>
                            <Form.Row>
                              <Form.Group as={Col} controlId="formGridCEP" xs={12}>
                                <Form.Label className="meu-cadastro-label"> CEP * </Form.Label>
                                <InputMask 
                                  mask="99.999-999" 
                                  value={this.state.customer.cep} 
                                  onChange={(event) => {
                                    let tmp = Diversos.getnums(event.target.value);
  
                                    if ( tmp.length >= 8 )
                                      this.setState({customer: {...this.state.customer, cep: event.target.value}}, this.getAddressByCep.bind(this))
                                    else
                                      this.setState({customer: {...this.state.customer, cep: event.target.value}})
                                  }}
                                >
                                  {(inputProps) => <Form.Control {...inputProps} type="text" className="user-input" required/>} 
                                </InputMask>
                              </Form.Group>
                            </Form.Row>
                            <Form.Row>
                              <Form.Group as={Col} controlId="formGridRua" xs={12} md={8}>
                                <Form.Label className="meu-cadastro-label"> Rua * </Form.Label>
                                <Form.Control className="user-input" value={this.state.customer.rua} onChange={(event) => this.setState({customer: {...this.state.customer, rua: event.target.value}})}/>
                              </Form.Group>
                              <Form.Group as={Col} controlId="formGridNumero" xs={12} md={4}>
                                <Form.Label className="meu-cadastro-label"> Número * </Form.Label>
                                <Form.Control className="user-input" value={this.state.customer.numero} onChange={(event) => this.setState({customer: {...this.state.customer, numero: event.target.value}})}/>
                              </Form.Group>
                            </Form.Row>
                            <Form.Row>
                              <Form.Group as={Col} ontrolId="formGridBairro">
                                <Form.Label className="meu-cadastro-label"> Bairro * </Form.Label>
                                <Form.Control className="user-input" value={this.state.customer.bairro} onChange={(event) => this.setState({customer: {...this.state.customer, bairro: event.target.value}})}/>
                              </Form.Group>
                            </Form.Row>
                            <Form.Row>
                              <Form.Group as={Col} controlId="formGridCidade">
                                <Form.Label className="meu-cadastro-label"> Cidade * </Form.Label>
                                <Form.Control className="user-input"  value={this.state.customer.cidade} onChange={(event) => this.setState({customer: {...this.state.customer, cidade: event.target.value}})}/>
                              </Form.Group>
                              <Form.Group as={Col} controlId="formGridEstado">
                                <Form.Label className="meu-cadastro-label"> Estado * </Form.Label>
                                <Form.Control as="select" value={this.state.customer.estado} className="user-input" onChange={(event) => this.setState({customer: {...this.state.customer, estado: event.target.value}})}>
                                  {
                                    Diversos.getUFs().map((row, index) => (
                                      <option key={index} value={row.value}>{row.label}</option>
                                    ))
                                  }
                                </Form.Control>
                              </Form.Group>
                            </Form.Row>
                            <Form.Row>
                              <Form.Group as={Col} controlId="formGridComplemento">
                                <Form.Label className="meu-cadastro-label"> Complemento </Form.Label>
                                <Form.Control className="user-input"  value={this.state.customer.complemento} onChange={(event) => this.setState({customer: {...this.state.customer, complemento: event.target.value}})}/>
                              </Form.Group>
                            </Form.Row>
                          </Col>
                        </Form>

                        <div className="user-actions">
                          <Button className="primary-action" type="button" onClick={this.handleSubmit.bind(this, 2)} disabled={this.state.isLoading}>
                          {(this.state.isLoading) ? 'Salvando...' : 'Salvar'}
                          </Button>
                          {/* <a className="logout-anchor" onClick={this.handleLogout.bind(this)}>
                            Logout
                          </a> */}
                        </div>
                      </>
                    }
                  </Tab>

                  {/* FORM TROCAR SENHA */}
                  <Tab eventKey="perfil" title="Trocar senha" id="trocar-senha" className="w-100 tab-content">
                    {
                      this.state.isLoadingCustomer ? 
                      <Spinner animation="border" role="status" variant="light" size="sm">
                        <span className="sr-only">Loading...</span>
                      </Spinner>
                      :
                      <>
                        <Form className="d-flex w-100 ">
                          <Col sm={12} md={9} xl={7} className="m-auto">
                            
                            <Form.Row>
                              <Form.Group as={Col} controlId="formGridPassword">
                                <Form.Label className="meu-cadastro-label"> Senha atual </Form.Label>
                                <Form.Control type="password" className="user-input"  onChange={(event) => this.setState({senhaAtual: event.target.value})}/>
                              </Form.Group>
                            </Form.Row>
                            <Form.Row>
                              <Form.Group as={Col} controlId="formGridPassword">
                                <Form.Label className="meu-cadastro-label"> Senha nova </Form.Label>
                                <Form.Control type="password" className="user-input"  onChange={(event) => this.setState({senhaNova: event.target.value})}/>
                              </Form.Group>
                            </Form.Row>
                            <Form.Row>
                              <Form.Group as={Col} controlId="formGridPassword">
                                <Form.Label className="meu-cadastro-label"> Confirmação senha </Form.Label>
                                <Form.Control type="password" className="user-input"  onChange={(event) => this.setState({confSenhaNova: event.target.value})}/>
                              </Form.Group>
                            </Form.Row>
                          </Col>
                        </Form>

                        <div className="user-actions">
                          <Button className="primary-action" type="button" onClick={this.handleSubmit.bind(this, 3)} disabled={this.state.isLoading}>
                          {(this.state.isLoading) ? 'Salvando...' : 'Salvar'}
                          </Button>
                          {/* <a className="logout-anchor" onClick={this.handleLogout.bind(this)}>
                            Logout
                          </a> */}
                        </div>
                      </>
                    }
                  </Tab>

                </Tabs>
              </div>

              <div className="user-actions">
                <a className="logout-anchor" onClick={this.handleLogout.bind(this)}>
                  Logout
                </a>
              </div>
            </div>
            :
            <> 
              <div className="form-container" id="container-user-signup">
                <h1 className="mt-3"> <span className="heading-border"> Cadastro </span> </h1>

                {
                  this.state.novoHasError &&
                  <div className="alert alert-danger">
                    <strong>{this.state.novoHasErrorTitle}</strong>
                    <br/>
                    {this.state.novoHasErrorMsg}
                  </div>
                }

                {/* LINHA COM STEPS */}
                <Row className="form-current-step">
                  <Col xs={4} sm={3} className="d-flex text-center">
                    <span>
                      <h2 className={this.stepStyling(1)}> 1 </h2>
                      <p className={this.stepStyling(1)}> Dados da conta </p>
                    </span>
                  </Col>
                  <Col xs={1} sm={1} className="d-none d-sm-flex hr-col">
                    <hr className={this.stepStyling(1)}/>
                  </Col>
                  <Col xs={4} sm={3} className="d-flex text-center">
                    <span>
                      <h2 className={this.stepStyling(2)}> 2 </h2>
                      <p className={this.stepStyling(2)}> Dados pessoais </p>
                    </span>
                  </Col>
                  <Col xs={1} sm={1} className="d-none d-sm-flex hr-col">
                    <hr className={this.stepStyling(2)}/>
                  </Col>
                  <Col xs={4} sm={3} className="d-flex text-center">
                    <span> 
                      <h2 className={this.stepStyling(3)}> 3 </h2>
                      <p className={this.stepStyling(3)}> Dados de entrega </p>
                    </span>
                  </Col>
                </Row>

                <div className="input-container" id="unlogged-user-input">
                  <Form className="d-flex w-100">
                    <Col className="form-col">
                      { 
                        (this.state.currentStep !== 1) ?
                        null 
                        :
                        <>
                          <Form.Row>
                            <Form.Group as={Col} controlId="formGridEmail" className="mb-3">
                              <Form.Label className="ml-3"> Email *</Form.Label>
                              <Form.Control type="email" className="user-input input-border" value={this.state.novoEmail} onChange={(event) => this.setState({novoEmail: event.target.value})} required/>
                            </Form.Group>
                          </Form.Row>
                          <Form.Row>
                            <Form.Group as={Col} controlId="formGridPassword">
                              <Form.Label className="ml-3"> Senha *</Form.Label>
                              <Form.Control type="password" className="user-input input-border" value={this.state.novoSenha} onChange={(event) => this.setState({novoSenha: event.target.value})} required/>
                            </Form.Group>
                          </Form.Row>

                          <Form.Row>
                            <Form.Group as={Col} controlId="formGridPasswordConfirm">
                              <Form.Label className="ml-3"> Confirme sua Senha *</Form.Label>
                              <Form.Control type="password" className="user-input input-border" value={this.state.novoConfSenha} onChange={(event) => this.setState({novoConfSenha: event.target.value})} required/>
                            </Form.Group>
                          </Form.Row>
                        </>
                      } 
                      
                      { 
                        (this.state.currentStep !== 2) ?
                        null 
                        :
                        <>
                          <Form.Row>
                            <Form.Group as={Col} controlId="formGridNome" className="mb-3">
                              <Form.Label className="ml-3"> Nome *</Form.Label>
                              <Form.Control type="text" className="user-input input-border" onChange={(event) => this.setState({novoNome: event.target.value})} required/>
                            </Form.Group>
                          </Form.Row>
                          <Form.Row>
                            <Form.Group as={Col} controlId="formGridCpf" className="mb-3">
                              <Form.Label className="ml-3"> CPF *</Form.Label>
                              <InputMask mask="999.999.999-99" onChange={(event) => this.setState({novoCpf: event.target.value})}>
                                {(inputProps) => <Form.Control {...inputProps} type="tel" className="user-input input-border" required/>}
                              </InputMask>
                            </Form.Group>
                          </Form.Row>
                          <Form.Row>
                            <Form.Group as={Col} controlId="formGridNascimento">
                              <Form.Label className="meu-cadastro-label"> Data de Nascimento * </Form.Label>
                              <InputMask mask="99/99/9999" onChange={(event) => this.setState({novoNascimento: event.target.value})} required>
                                {(inputProps) => <Form.Control {...inputProps} type="tel" className="user-input"/>}
                              </InputMask>
                            </Form.Group>
                          </Form.Row>
                          <Form.Row>
                            <Form.Group as={Col} controlId="formGridTelefone" className="mb-3">
                              <Form.Label className="ml-3"> Celular *</Form.Label>
                              <InputMask mask="(99) 9 9999-9999" onChange={(event) => this.setState({novoCelular: event.target.value})}>
                                {(inputProps) => <Form.Control {...inputProps} type="tel" className="user-input input-border" required/>}
                              </InputMask>
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridTelefone2" xs={12} sm={6}>
                              <Form.Label className="meu-cadastro-label"> Telefone 2 </Form.Label>
                              <InputMask mask="(99) 9 9999-9999" onChange={(event) => this.setState({novoTelefone: event.target.value})}>
                                {(inputProps) => <Form.Control {...inputProps} type="tel" className="user-input"/>}
                              </InputMask>
                            </Form.Group>
                          </Form.Row>
                        </>
                      } 

                      { 
                        (this.state.currentStep !== 3) ?
                        null 
                        :
                        <>
                          <Form.Row>
                            <Form.Group as={Col} controlId="formGridCEP" xs={12}>
                              <Form.Label className="meu-cadastro-label"> CEP * </Form.Label>
                              <InputMask 
                                mask="99.999-999"
                                ref={this.novoCepRef}
                                disabled={this.state.isLoadingCep}
                                onChange={(event) => {
                                  let tmp = Diversos.getnums(event.target.value);

                                  if ( tmp.length >= 8 )
                                    this.setState({novoCep: event.target.value}, this.getAddressByCep.bind(this))
                                  else
                                    this.setState({novoCep: event.target.value})
                                }}
                              >
                                {(inputProps) => <Form.Control {...inputProps} type="text" className="user-input" required/>} 
                              </InputMask>
                            </Form.Group>
                          </Form.Row>
                          <Form.Row>
                            <Form.Group as={Col} controlId="formGridRua" xs={12} md={8}>
                              <Form.Label className="meu-cadastro-label"> Rua * </Form.Label>
                              <Form.Control className="user-input" value={this.state.novoRua} ref={this.novoRuaRef} disabled={this.state.isLoadingCep} onChange={(event) => this.setState({novoRua: event.target.value})} required/>
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridNumero" xs={12} md={4}>
                              <Form.Label className="meu-cadastro-label"> Número * </Form.Label>
                              <Form.Control className="user-input" value={this.state.novoNumero} ref={this.novoNumeroRef} disabled={this.state.isLoadingCep} onChange={(event) => this.setState({novoNumero: event.target.value})} required/>
                            </Form.Group>
                          </Form.Row>
                          <Form.Row>
                            <Form.Group as={Col} ontrolId="formGridBairro">
                              <Form.Label className="meu-cadastro-label"> Bairro * </Form.Label>
                              <Form.Control className="user-input" value={this.state.novoBairro} ref={this.novoBairroRef} disabled={this.state.isLoadingCep} onChange={(event) => this.setState({novoBairro: event.target.value})} required/>
                            </Form.Group>
                          </Form.Row>
                          <Form.Row>
                            <Form.Group as={Col} controlId="formGridCidade">
                              <Form.Label className="meu-cadastro-label"> Cidade * </Form.Label>
                              <Form.Control value={this.state.novoCidade} ref={this.novoCidadeRef} disabled={this.state.isLoadingCep} className="user-input" onChange={(event) => this.setState({novoCidade: event.target.value})} required/>
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEstado">
                              <Form.Label className="meu-cadastro-label"> Estado * </Form.Label>
                              <Form.Control as="select" className="user-input" value={this.state.novoEstado} ref={this.novoUfRef} disabled={this.state.isLoadingCep} onChange={(event) => this.setState({novoEstado: event.target.value})} required>
                                {
                                  Diversos.getUFs().map((row, index) => (
                                    <option key={index} value={row.value}>{row.label}</option>
                                  ))
                                }
                              </Form.Control>
                            </Form.Group>
                          </Form.Row>
                          <Form.Row>
                            <Form.Group as={Col} controlId="formGridComplemento">
                              <Form.Label className="meu-cadastro-label"> Complemento </Form.Label>
                              <Form.Control className="user-input" onChange={(event) => this.setState({novoComplemento: event.target.value})}/>
                            </Form.Group>
                          </Form.Row>
                        </>
                      } 

                      <Row className="multistep-controller">
                        {
                          (this.state.currentStep !== 1)  ?
                          <button
                          className="btn btn-link-action text-center mx-2"
                          type="button"
                          onClick={this.handleSubmitNovo.bind(this, 1)}>
                            <FontAwesomeIcon icon={faArrowLeft} className="mx-1"/> Voltar
                          </button>
                          :
                          null
                        }

                        {
                          (this.state.currentStep < 3) ?
                          <button
                          className="btn btn-link-action mx-2"
                          type="button"
                          onClick={this.handleSubmitNovo.bind(this, 2)}>
                            Próximo <FontAwesomeIcon icon={faArrowRight} className="mx-1" />
                          </button>
                          :
                          null
                        }
                      </Row>
                    </Col>
                  </Form>

                </div>

                {
                  (this.state.currentStep === 3) ?
                  <button type="submit" className="btn btn-lg primary-action" onClick={this.handleSubmitNovo.bind(this)} disabled={this.state.isLoadingNovo}>
                    {
                      this.state.isLoadingNovo ?
                      <>
                        <Spinner animation="border" role="status" variant="light" size="sm">
                          <span className="sr-only">Loading...</span>
                        </Spinner>
                      </>
                      :
                      <>Cadastrar</>
                    }
                  </button>
                  :
                  null
                }

                <h3 className="mt-5"> <span className="font-size-16"> Possui uma conta? </span> <a href="/login"> Faça Login </a> </h3>
              </div>              
            </>
          }
        </section>

        <Footer/>
      </>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.data
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({...UserActions}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Cadastro);