import React from 'react';
import './index.scss';
import { isScopable } from '@babel/types';
import { isObject } from 'util';
import { Link, Redirect } from 'react-router-dom';
import Api from '../../services/api';
import { Diversos } from '../../services/diversos';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ApplicationState } from '../../store';
import { Param, ParamTypes } from '../../store/ducks/param/types';
import { User, UserTypes } from '../../store/ducks/user/types';
import * as UserActions from '../../store/ducks/user/actions';
import * as ParamActions from '../../store/ducks/param/actions';
import Cart from '../Cart'
import MultilevelSidebar from "react-multilevel-sidebar";
import "react-multilevel-sidebar/src/Sidebar.css";
import Slider from "react-slick";


// Import de Icones
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faUserCircle, faThLarge, faShoppingCart, faChevronDown, faBars, faSearch } from '@fortawesome/free-solid-svg-icons'
import { faInstagram, faFacebookSquare, faWhatsapp, faYoutube } from '@fortawesome/free-brands-svg-icons'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'

// Import de Imagens
import logo from '../../assets/Images/logo.png'

import BurgerMenu from '../BurgerMenu'
import NavigationItem from '../NavigationItem'

// Import de Componentes Bootstrap
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Modal from 'react-bootstrap/Modal'
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import Button from 'react-bootstrap/Button'



declare var window;

interface StateProps {
  user: User;
  param: Param;
}

interface OwnProps {
  searchTermo?: string;
  tipo?: number;
}

interface DispatchProps {
  doLogin(user: User): void;
  setParam(param: Param): void;
}

type Props = StateProps & DispatchProps & OwnProps;



class Header extends React.Component<Props> {

  api: any = null;
  state: any = null;
  searchbarRef: any;;

  constructor(props) {
    super(props);
    this.api = new Api();
    this.searchbarRef = React.createRef();
    this.state = {
      isLoading: false,
      menu: null,
      searchTerm: (this.props.searchTermo) ? this.props.searchTermo : null,
      searchbarHasError: false,
      redirect: null,
      sidebarOpened: false,
      options: [
        {
          title: "Navegação",
          content: [
            {
              id: 1,
              name: "Departamentos",
              children: [{ content: [] }]
            },
            { id: 2, name: "Promoções" },
            { id: 3, name: "Ofertas do mês" },
            { id: 4, name: "Solicitar Orçamento" }
          ]
        },
        {
          title: "SAC",
          content: [
            {
              id: 5,
              name: "Minha conta",
              children: [
                {
                  content: [
                    { id: 51, name: "Meus pedidos", to: "/meus-pedidos" },
                    { id: 52, name: "Dados da conta", to: "/cadastro"},
                    { id: 53, name: "Trocar senha", to: "/cadastro#trocar-senha" }
                  ]
                }
              ]
            },
            { id: 6, name: "Fale conosco" },
            { id: 7, name: "Nossas lojas" },
            { id: 8, name: "Blog" },
            { id: 9, name: "A Hidroeletrica" }
          ]
        }
      ]
    }
  }



  //   you can also use this function on any of your event to open/close the sidebar
  handleSidebarToggle = isOpen => {
    this.setState({ sidebarOpened: isOpen });
  };

  handleClick = itemOptions => {
    /* 
        do something with the item you clicked.
        you can also send custom properties of your choice
        in the options array you'll be getting those here
        whenever you click that item
    */
  };


  async componentDidMount() {
    const self = this;

    self.setState({ isLoading: true });

    try {
      const { data } = await self.api.get('/menu/nivel1');

      if ( data.status === false ) {
        throw new Error('Nao foi possível recuperar menu');
      } else {
        let aux: any = [];
        let aux2: any = [];

        for (var i = 0; i < data.msg.length; i++) {
          aux.push({
            codigo: data.msg[i].CDMENU,
            descricao: data.msg[i].DESCRICAO,
            menu2: data.msg[i].menu2,
          });

          aux2.push({
            id: i,
            name: Diversos.capitalize(data.msg[i].DESCRICAO),
            to: "/departamento/" + Diversos.toSeoUrl(data.msg[i].DESCRICAO),
          });
        }

        let tmp = self.state.options;
        tmp[0].content[0].children[0].content = aux2;

        self.setState({ menu: aux, options: tmp });
      }

    } catch (e) {
      console.error(e);
      self.setState({ menu: null });
    } finally {
      self.setState({ isLoading: false });
    }
  }

  /**
   * FAZ TRATAMENTO DO BOTAO DE PESQUISA
   */
  private async handleSearch() {
    const self = this;
    if (!self.state.searchTerm) {
      self.searchbarRef.current.focus();
      self.setState({ searchbarHasError: true });
    } else {
      self.setState({ redirect: `/busca/${Diversos.toSeoUrl(self.state.searchTerm)}` });
      window.location.href = `/busca/${Diversos.toSeoUrl(self.state.searchTerm)}`;
    }
  }

  render() {


    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }

    return (
      <>
        {
          ((!this.props.tipo) || (this.props.tipo != 2)) ?
            <>
              <header>
                <Row className="d-flex upper-bar">
                  <p className="d-none d-md-block"> <strong> 41 3356-3233 </strong> </p>
                  <p style={{fontSize: 12}}> Rua Theodoro Makiolka, 885 - Santa Cândida </p>
                  <p style={{fontSize: 12}} className="d-none d-sm-block"> Curitiba - PR </p>
                </Row>
                <Row className="top-bar">
                  <Slider
                  className="top-bar-slider"
                  dots={false}
                  arrows={false}
                  autoplay={true}
                  infinite={true}
                  speed={1500}
                  autoplaySpeed={6000}
                  slidesToShow={3}
                  slidesToScroll={1}
                  responsive={[
                    {
                      breakpoint: 1290,
                      settings: {
                        slidesToShow: 1,
                      }
                    },
                    {
                      breakpoint: 768,
                      settings: {
                        slidesToShow: 1,
                      }
                    },
                    {
                      breakpoint: 576,
                      settings: {
                        slidesToShow: 1,
                      }
                    },
                    {
                      breakpoint: 440,
                      settings: {
                        slidesToShow: 1,
                      }
                    }
                  ]}>
                    <Col className="upper-nav-col" style={{paddingRight: 10}}>
                      <ul className="navigation-bar">
                        <li className="d-none d-sm-block">
                          <a href="/empresa" target="_self">Quem somos</a>
                        </li>
                        <li>
                          <a href="/como-comprar" target="_self">Como comprar</a>
                        </li>
                        <li>
                          <a href="/fale-conosco" target="_self">Fale Conosco</a>
                        </li>
                        <li>
                          <a href="/cadastro" target="_self">Fazer Cadastro</a>
                        </li>
                      </ul>
                    </Col>
                    <Col className="delivery-types">
                      <p> ENTREGA PAC | SEDEX | TOTAL EXPRESS </p>
                    </Col>
                    <Col className="useful-links">
                      <p> Receba Ofertas via Whatsapp </p>
                      <a href="https://api.whatsapp.com/send?phone=554133563233&text=Olá!%20Gostaria%20de%20receber%20ofertas%20neste%20número..." target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faWhatsapp} className="header-icon"/>
                      </a>
                      <p> <strong> 41 3356-3233 </strong> </p>
                      <h4 className="d-none d-sm-block"> | </h4>
                      <a href="https://www.facebook.com/hidroeletrica.colombo" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faFacebookSquare} className="d-none d-sm-flex header-icon"/>
                      </a>
                      <a href="https://www.instagram.com/hidroeletrica_colombo/" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faInstagram} className="d-none d-sm-flex header-icon"/>
                      </a>
                      {/* <FontAwesomeIcon icon={faYoutube} className="d-none d-sm-flex header-icon"/> */}
                    </Col>
                  </Slider>
                </Row>
                <Row className="middle-bar">
                  <Col xs={3} lg={3} className="px-0 d-none d-sm-block">
                    <div className="img-container">
                      <a href="/"> <img src={logo}/> </a>
                    </div>
                  </Col>
                  <Col sm={9} lg={6} className="search-bar">
                  <InputGroup>
                    <FormControl
                      className="search-box-input"
                      placeholder="Busque aqui o que você precisa"
                      aria-label="Busque aqui o que você precisa"
                      aria-describedby="Barra de pesquisa"
                      onChange={(event) => this.setState({ searchTerm: event.target.value })}
                      onKeyPress={(event) => event.key === 'Enter' && this.handleSearch()} />
                    <InputGroup.Append>
                      <Button variant="primary" onClick={this.handleSearch.bind(this)} style={{borderRadius: 0}}>
                        <FontAwesomeIcon icon={faSearch}/>
                      </Button>
                    </InputGroup.Append>
                  </InputGroup>
                  </Col>
                  <Col xs={12} lg={3} className="user-links">
                    <span className="d-flex d-lg-none user-link md-force-left" onClick={() => this.handleSidebarToggle(true)}>
                      <FontAwesomeIcon icon={faBars} className="header-icon mx-0 text-left"/>
                    </span>
                    <a className="user-link" href={((this.props.user) && (this.props.user.status === true)) ? "/cadastro" : "/login"}
                      target="_self" title={((this.props.user) && (this.props.user.status === true)) ?
                        "Meu cadastro" : "Fazer login"}>
                      <FontAwesomeIcon icon={faUser} className="header-icon"></FontAwesomeIcon>
                      <span>
                        <a href={((this.props.user) && (this.props.user.status === true)) ? '/cadastro' : '/login'} style={{color: 'white'}}>
                          {
                            ((this.props.user) && (this.props.user.status === true)) &&
                            <>
                             {this.props.user.nome.split(" ")[0]}
                             <br/>
                            </>
                          }
                          MINHA CONTA
                        </a>
                        <br/> 
                        <small>
                          <a href={((this.props.user) && (this.props.user.status === true)) ? '/meus-pedidos' : '/login'} style={{color: 'white'}}>
                            MEUS PEDIDOS
                          </a>
                        </small>
                      </span>
                    </a>
                    <span className="user-link md-force-right" onClick={() => this.props.setParam({ ...this.props.param, cartOpened: true })}>
                      <FontAwesomeIcon icon={faShoppingCart} className="header-icon mx-0" />
                    </span>
                  </Col>
                </Row>
                <Row className="d-none d-lg-flex main-nav-container">
                  <ul className="navigation-bar main-navigation">
                  {
                    (this.state.isLoading) ?
                    <li> <FontAwesomeIcon icon="spinner" spin /> </li>
                    :
                    ((this.state.menu) && (this.state.menu.length > 0)) ?
                      this.state.menu.map((row, index) => (
                        <>
                          <li key={index}>
                            <a href={`/departamento/${Diversos.toSeoUrl(row.descricao)}`} target="_self">
                              {Diversos.capitalize(row.descricao)}
                            </a>
                            {
                              ((row.menu2) && (row.menu2.length > 0)) &&
                              <ul className="dropdown-lvl-one">
                                {
                                  row.menu2.map((row2, index2) => (
                                    <li key={index2}>
                                      <a href={`/departamento/${Diversos.toSeoUrl(row.descricao)}/${Diversos.toSeoUrl(row2.DESCRICAO)}`} target="_self">
                                        {row2.DESCRICAO}
                                      </a>
                                      {
                                        ((row2.menu3) && (row2.menu3.length > 0)) &&
                                        <ul className="dropdown-lvl-two">
                                          {
                                            row2.menu3.map((row3, index3) => (
                                              row3.DESCRICAO !== '' ? 
                                              <li key={index3}> <a href={`/departamento/${Diversos.toSeoUrl(row.descricao)}/${Diversos.toSeoUrl(row2.DESCRICAO)}/${Diversos.toSeoUrl(row3.DESCRICAO)}`} target="_self"> {row3.DESCRICAO} </a> </li>
                                              :
                                              <></>
                                            ))
                                          }
                                        </ul>
                                      }
                                    </li>
                                  ))
                                }
                              </ul>
                            }
                          </li>

                          {
                            (index < (this.state.menu.length - 1)) &&
                            <p className="m-0">|</p>
                          }
                        </>
                      ))
                      :
                      null
                    }
                    {/* <li>ELÉTRICA</li> <p className="m-0">|</p>
                    <li>LED</li> <p className="m-0">|</p>
                    <li>HIDRAULICA</li> <p className="m-0">|</p>
                    <li>FERRAGENS</li> <p className="m-0">|</p>
                    <li>FERRAMENTAS</li> <p className="m-0">|</p>
                    <li>TINTAS</li> <p className="m-0">|</p>
                    <li>UTILIDADES</li> <p className="m-0">|</p>
                    <li>ASSENTOS</li> <p className="m-0">|</p>
                    <li id="hide">AQUECIMENTO</li> <p className="m-0" id="hide">|</p>
                    <li id="hide">METAIS</li> */}
                  </ul>
                </Row>


                {/* Menu Desktop */}
                {/* <nav id="lg-nav" className="d-none d-lg-flex">
                  <Row className="top-bar">
                    <Col lg={9} className="search-bar">
                      <input
                        type="text"
                        className="search-box-input"
                        placeholder="Buscar produtos em nossa loja"
                        onChange={(event) => this.setState({ searchTerm: event.target.value })}
                        onKeyPress={(event) => event.key === 'Enter' && this.handleSearch()} />
                      <span>
                        <p>SEG A SEXTA</p>
                        08:30 às 18:30
                      </span>
                      <span>
                        <p>SÁBADO</p>
                        08:30 às 13:00
                      </span>
                    </Col>

                  </Row>
                  <div className="bottom-bar">
                    <ul className="navigation-bar">
                      <li> <FontAwesomeIcon icon={faThLarge} className="categories-icon" />
                      Departamentos <FontAwesomeIcon icon={faChevronDown} />

                        {
                          (this.state.isLoading) ?
                            <ul className="dropdown-lvl-one">
                              <li> <FontAwesomeIcon icon="spinner" spin /> </li>
                            </ul>
                            :
                            ((this.state.menu) && (this.state.menu.length > 0)) ?
                              <ul className="dropdown-lvl-one">
                                {
                                  this.state.menu.map((row, index) => (
                                    <li key={index}>
                                      <a href={`/departamento/${Diversos.toSeoUrl(row.descricao)}`} target="_self">
                                        {Diversos.capitalize(row.descricao)}
                                      </a>
                                    </li>
                                  ))
                                }
                              </ul>
                              :
                              null
                        }
                      </li>
                      <li> Promoções </li>
                      <li id="hide-nav-item"> A Hidroeletrica </li>
                      <li id="hide-nav-item"> Lojas </li>
                      <li> Solicitar Orçamento </li>
                      <li> Fale conosco </li>
                      <li> Blog </li>
                      <li> Ofertas do mês </li>
                    </ul>
                  </div>
                </nav> */}

                {/* Menu Mobile */}
                {/* <nav id="md-nav" className="d-lg-none">
                  <Row className="w-100">
                    <Col xs={12}>
                      <input type="text" className="search-box-input" placeholder="Buscar produtos em nossa loja"></input>
                    </Col>
                  </Row>
                  <Row className="w-100">
                    <Col xs={6}>
                      <span className="user-link force-left" onClick={() => this.handleSidebarToggle(true)}>
                        <FontAwesomeIcon icon={faBars} className="header-icon" />
                      </span>
                    </Col>
                    <Col xs={6}>
                      <span className="user-link force-right" onClick={() => this.props.setParam({ ...this.props.param, cartOpened: true })}>
                        <FontAwesomeIcon icon={faShoppingCart} className="header-icon" />
                      </span>
                    </Col>
                  </Row>
                </nav> */}
              </header>
            </>
            :
            <>
              <header className="checkout-header">
                <Container fluid className="checkout-nav">
                  <Row className="w-100 m-auto d-flex align-items-center">
                    <Col className="d-flex justify-content-center align-items-center" lg="12" md="12" xs="12">
                      <Link to="/"> <img src={logo} className="logo" alt="logo" /> </Link>
                    </Col>
                  </Row>
                </Container>
              </header>
            </>
        }
        
        <Modal
          show={this.props.param.cartOpened}
          onHide={() => this.props.setParam({ ...this.props.param, cartOpened: false })}
          dialogClassName="cart-container"
          className="px-0"
          aria-labelledby="example-custom-modal-styling-title">
          <Cart />
        </Modal>
        <MultilevelSidebar
          open={this.state.sidebarOpened}
          onToggle={this.handleSidebarToggle}
          options={this.state.options}
          onItemClick={this.handleClick}
          wrapperClassName="sidebar-mobile-menu"
          header={
            <>
              <Container className="sidebar-header">
                <a className="sidebar-user-link" href={((this.props.user) && (this.props.user.status === true)) ? "/cadastro" : "/login"}
                  target="_self" title={((this.props.user) && (this.props.user.status === true)) ?
                    "Meu cadastro" : "Fazer login"}>
                  <FontAwesomeIcon icon={faUserCircle} className="sidebar-user-icon"></FontAwesomeIcon>
                  <span className="sidebar-user-action">
                    <span className="sidebar-user-title"> Meu perfil </span>
                    {((this.props.user) && (this.props.user.status === true)) ? <span> Dados da conta </span> : <span> Cadastre-se ou faça login </span>}
                  </span>
                </a>
              </Container>
            </>
          }
        />
      </>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.data,
  param: state.param.data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...UserActions, ...ParamActions }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Header);