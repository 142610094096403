import React, { Component } from 'react';
import './index.scss';

import Header from '../../components/Header'
import Footer from '../../components/Footer'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Missao from '../../assets/Images/missao.png';
import Atendimento from '../../assets/Images/atendimento.png';
import Demanda from '../../assets/Images/demanda.png';
import Cadastro from '../../assets/Images/cadastro.png';
import Entrega from '../../assets/Images/entrega.png';
import logo from '../../assets/Images/logo.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';


class TrocaDevolucao extends Component <{}> {

  state: any = {};

  constructor (props: any) {
    super(props);
  }

  render () {
    return (
      <>

        <Header/>

        <section className="institucional">
          <h1 className="mt-3"> <span className="heading-border"> Política de troca e devolução </span> </h1>
          <article className="sobre-nos-texto">
            <p>
              
            </p>
          </article>
          
          <Row className="sobre-lojas">
            <Col xs={12} xl={12}>
              <p><strong>Orientações Gerais</strong></p>
              <p>Ao receber seu pedido confira se todos os produtos foram entregues em suas embalagens originais, devidamente lacradas.
              Se no momento da entrega do pedido constatar alguma irregularidade, como embalagem ou produto avariado, por favor recuse o mesmo. </p>
              <p>Caso ocorra o recebimento entre em contato com a nossa <a href="mailto:sac@hidroeletrica.com"> Central de Atendimento ao Cliente </a> em até 3 dias úteis.</p>
              <p>Confira as instruções de instalação que acompanham o produto adquirido no que diz respeito à forma adequada de utilização, para não perder a garantia fornecida pelo fabricante do produto.</p>
              <p>Guarde a nota fiscal que acompanha sua compra.</p>
              
              <p><strong>Devoluções:</strong></p>
              <p>Nos casos de arrependimento ou insatisfação, o cliente deverá efetivar sua solicitação de devolução em até 7 (sete) dias corridos, a contar da data do recebimento do produto, através da central de atendimento. O produto deve estar em perfeitas condições de uso e em sua embalagem original, com todos os acessórios. </p>
              <p>Ao receber o contato, nossa central de atendimento ao cliente irá orientar quanto ao procedimento de devolução, que deverá ser feito na agência mais próxima dos Correios utilizando uma autorização de postagem. O produto deverá estar na embalagem original.</p>
              
              <p><strong>Trocas:</strong></p>
              <p>Caso o cliente deseje efetuar a troca por outro produto (ex: voltagem do produto é diferente do pedido), pedimos a gentileza de entrar em contato com a nossa central de atendimento em até 7 dias do recebimento para solicitar orientações sobre a troca. Podemos orientar quanto às dificuldades e providenciar a devolução e envio do novo produto. O produto deverá estar novo (sem uso) para efetuarmos a troca.</p>
              <p>Para realizar a troca, primeiramente o cliente deve entrar em contato com nossa central de atendimento no telefone ou email informado abaixo. Então produto deverá ser embalado na caixa original e entregue na agência mais próxima dos Correios, utilizando a autorização de postagem fornecida pela central de atendimento ao cliente no momento do contato inicial. O frete da troca será por conta do cliente, caso haja diferença de valor entre o produto comprado e o produto para troca, ela será cobrada ou ressarcida (a maior ou a menor, se houver). O novo produto será enviado na sequência pelos Correios ou Transportadora para o endereço informado pelo cliente no momento da solicitação de troca. O processo de troca pode ser solicitado e acompanhado por email ou telefone, através da nossa central de atendimento.</p>
              
              <p><strong>Garantia:</strong></p>
              <p>Caso algum produto apresente defeito dentro do prazo de garantia, procure os serviços da assistência técnica, siga as instruções recebidas para o devido reparo ou substituição . É importante lembrar que deve guardar a Nota Fiscal que acompanha o produto.</p>
              
              <p><strong>Reembolso:</strong></p>
              <p>Para os casos de reembolso, o mesmo será realizado de acordo com a forma de pagamento: para compras realizadas por boleto ou depósito, será solicitada uma conta bancária para depósito, que será realizado em até 5 dias úteis a partir do recebimento do numero da conta; para compras feitas por meio de cartão de crédito, o estorno/reembolso ocorrerá em até 02 (duas) faturas subsequentes, a partir da data de solicitação de estorno entregue pela Hidroelétrica junto à administradora do cartão de crédito, sendo o estorno de responsabilidade da respectiva administradora. </p>
              <p>Toda solicitação de troca ou devolução deve ser efetivada em nossa central de atendimento  dentro dos prazos estabelecidos, não sendo aceitas solicitações fora dos prazos estipulados.</p>
            </Col>
          </Row>
        </section>

        <Footer/>

      </>
    );
  }
}

export default TrocaDevolucao;
