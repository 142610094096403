import React, {Component} from 'react';
import axios from 'axios';

const apiAxio = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL
});

export default class Api {

  public async authorize () {
    try {
      let headers = {};
      headers['Content-Type'] = 'application/json';
      headers['Accept'] = 'application/json';
      headers['Access-Control-Allow-Origin'] = '*';

      var bodyEnd = {
        email: "suporte@tecworks.com.br",
        password: "12345",
        HTTP_X_AUTH_TOKEN: "fGtaUSg0Q2J8MC9XU2UxNSo4JnJ6c2JiT2hZe2JXaWMyNFVXdlZ7X3F2OG9iNDxYbG1LR3VeK0A8JD02UDtp"
      };

      const {data} = await apiAxio.post("/sessions", bodyEnd, { headers });
    } catch (e) {
      console.error('api (ERROR): ', e);
    }
  }

  public async get (url: string) {
    try {
      let headers = {};
      headers['Accept'] = 'application/json';
      headers['Access-Control-Allow-Origin'] = '*';      
      headers['Authorization'] = ' Bearer ' + process.env.REACT_APP_API_TOKEN;

      return apiAxio.get(url, {headers});
    } catch (e) {
      console.error('api (ERROR): ', e);
    }
  }

  public async post (url: string, body: any = null) {
    try {
      let headers = {};
      headers['Content-Type'] = 'application/json';
      headers['Accept'] = 'application/json';
      headers['Access-Control-Allow-Origin'] = '*';
      headers['Authorization'] = ' Bearer ' + process.env.REACT_APP_API_TOKEN;

      var bodyEnd = {
        ...body,
        HTTP_X_AUTH_TOKEN: "fGtaUSg0Q2J8MC9XU2UxNSo4JnJ6c2JiT2hZe2JXaWMyNFVXdlZ7X3F2OG9iNDxYbG1LR3VeK0A8JD02UDtp"
      };

      return apiAxio.post(url, bodyEnd, { headers });
    } catch (e) {
      console.error('api (ERROR): ', e);
    }
  }

  public async put (url: string, body: any = null) {
    try {
      let headers = {};
      headers['Content-Type'] = 'application/json';
      headers['Accept'] = 'application/json';
      headers['Access-Control-Allow-Origin'] = '*';
      headers['Authorization'] = ' Bearer ' + process.env.REACT_APP_API_TOKEN;

      var bodyEnd = {
        ...body,
        HTTP_X_AUTH_TOKEN: "fGtaUSg0Q2J8MC9XU2UxNSo4JnJ6c2JiT2hZe2JXaWMyNFVXdlZ7X3F2OG9iNDxYbG1LR3VeK0A8JD02UDtp"
      };

      return apiAxio.put(url, bodyEnd, { headers });
    } catch (e) {
      console.error('api (ERROR): ', e);
    }
  }

  public async delete (url: string) {
    try {
      let headers = {};
      headers['Accept'] = 'application/json';
      headers['Access-Control-Allow-Origin'] = '*';
      headers['Authorization'] = ' Bearer ' + process.env.REACT_APP_API_TOKEN;

      return apiAxio.delete(url, {headers});
    } catch (e) {
      console.error('api (ERROR): ', e);
    }
  }
}
