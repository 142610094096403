import React, { Component } from 'react';
import './index.scss';
import Api from '../../services/api';

import Header from '../../components/Header'
import Footer from '../../components/Footer'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt, faMapMarkerAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp, faFacebookSquare, faInstagram } from '@fortawesome/free-brands-svg-icons';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';


class FaleConosco extends Component <{}> {

  api: any = null;
  state: any = {};

  constructor (props: any) {
    super(props);
    this.api = new Api();

    this.state = {
      isLoading: false,
      hasError: false,
      hasErrorMsg: null,
      hasSuccess: false,
      hasSuccessMsg: null,
      nome: null,
      email: null,
      mensagem: null,
    }
  }

  private async _handleSubmit () {
    const self = this;

    if (( !self.state.nome ) || ( !self.state.email ) || ( !self.state.mensagem )) {
      self.setState({
        hasError: true,
        hasErrorMsg: "Formulário incompleto, por favor preencha todos os campos."
      });

      return false;
    }

    let param = {
      nome: self.state.nome,
      email: self.state.email,
      fone: self.state.fone,
      mensagem: self.state.mensagem,
    }

    self.setState({isLoading: true, hasError: false, hasSuccess: false});

    try {
      const {data} = await self.api.post("/contact", param);

      if ( !data.status ) {
        throw new Error('Não foi possível enviar mensagem, por favor tente novamente.');
      } else {
        self.setState({hasSuccess: true, hasSuccessMsg: "Mensagem enviada com sucesso!"});
      }

    } catch (e) {
      console.error(e);
      self.setState({hasError: true, hasErrorMsg: "Não foi possível enviar mensagem!"});
    } finally {
      self.setState({isLoading: false});
    }
  }

  render () {
    return (
      <>

        <Header/>

        <section className="institucional">
          <h1 className="mb-5"> <span className="heading-border"> Fale Conosco </span> </h1>
          
          {
              this.state.hasError && 
              <Row className="mt-5">
                <Col xl={12}>
                  <div className="alert alert-danger">
                    <i className="fas fa-times mr-1"></i>
                    {this.state.hasErrorMsg}
                  </div>
                </Col>
              </Row>
            }

            {
              this.state.hasSuccess && 
              <Row className="mt-5">
                <Col xl={12}>
                  <div className="alert alert-success">
                    <i className="fas fa-check mr-1"></i>
                    {this.state.hasSuccessMsg}
                  </div>
                </Col>
              </Row>
            }

            <Row className="mt-5">
              <Col className="mx-auto text-center"> 
                <Form className="text-left fale-conosco">
                  <Form.Group>
                    <Form.Label> <strong> Seu nome </strong> </Form.Label>
                    <Form.Control type="text" placeholder="Nome" onChange={(event) => this.setState({nome: event.target.value})} className="user-input input-border"/>
                  </Form.Group>

                  <Form.Group>
                    <Form.Label> <strong> Seu email </strong></Form.Label>
                    <Form.Control type="email" placeholder="E-mail" onChange={(event) => this.setState({email: event.target.value})} className="user-input input-border"/>
                  </Form.Group>

                  <Form.Group>
                    <Form.Label> <strong> Sua mensagem </strong> </Form.Label>
                    <Form.Control as="textarea"  placeholder="Mensagem" onChange={(event) => this.setState({mensagem: event.target.value})} className="contato-textarea input-border"/>
                  </Form.Group>
                </Form>
                <Button variant="primary" type="submit" title="Enviar" className="my-3 btn-primary-action" onClick={this._handleSubmit.bind(this)}>
                {
                  this.state.isLoading ? 
                  `Enviando mensagem...`
                  :
                  `Enviar` 
                }
                </Button>
              </Col>
            </Row>
            <Col className="mt-5 fones">
              <Row>
                <Col lg={4} className="fones-container">
                  <div className="fones-loja">
                    <FontAwesomeIcon icon={faMapMarkerAlt}/>
                    <h3> Centro de Distribuição </h3>
                  </div>
                  <a href="tel:+554133563233">
                    <FontAwesomeIcon icon={faWhatsapp}/> 
                    (41) 3356-3233
                  </a>
                  <a href="mailto:vendas@hidroeletrica.com">
                    <FontAwesomeIcon icon={faEnvelope}/> 
                    vendas@hidroeletrica.com
                  </a>
                  <a target="_blank" rel="noopener noreferrer" href="https://www.google.com/search?tbm=lcl&sxsrf=ALeKk00pcUgessO4pd-caq4-EYGdcjiaJQ%3A1598446979501&ei=g11GX6uVHsS95OUPuMKEoAk&q=hidroeletrica+colombo+santa+candida&oq=hidroeletrica+colombo+santa+candida&gs_l=psy-ab.3..38.247371.248790.0.248915.14.9.0.0.0.0.158.823.0j6.6.0....0...1c.1.64.psy-ab..8.6.820...0j0i67k1j0i22i30k1.0.5HZwDJRAIUA#rlfi=hd:;si:13307428552110789847;mv:[[-25.355850999999998,-49.2099312],[-25.4495394,-49.2926375]]" style={{fontSize: 14}}>
                    {/* <FontAwesomeIcon icon={faWhatsapp}/>  */}
                    Rua Theodoro Makiolka, 885 - Santa Cândida
                  </a>
                </Col>
                <Col lg={4} className="fones-container">
                  <div className="fones-loja">
                    <FontAwesomeIcon icon={faMapMarkerAlt}/>
                    <h3> Loja Batel </h3>
                  </div>
                  <a href="tel:+554130263233">
                    <FontAwesomeIcon icon={faPhoneAlt}/> 
                    (41) 3026-3233
                  </a>
                  <a target="_blank" rel="noopener noreferrer" href="https://www.google.com/search?q=hidroeletrica%20colombo&oq=hidroeletrica+colo&aqs=chrome.0.0l3j69i57j0j69i61j69i60j69i61.2731j0j9&sourceid=chrome&ie=UTF-8&sxsrf=ALeKk02_jiI6ysVBl2YNy4pGINvsd7R_qA:1598446965781&npsic=0&rflfq=1&rlha=0&rllag=-25418608,-49251284,5389&tbm=lcl&rldimm=18434280057180022201&lqi=ChVoaWRyb2VsZXRyaWNhIGNvbG9tYm9aLgoVaGlkcm9lbGV0cmljYSBjb2xvbWJvIhVoaWRyb2VsZXRyaWNhIGNvbG9tYm8&phdesc=lVtGgOUSI44&ved=2ahUKEwiTtdn89rjrAhWWILkGHRHtAGoQvS4wAXoECA0QMg&rldoc=1&tbs=lrf:!1m4!1u3!2m2!3m1!1e1!1m4!1u2!2m2!2m1!1e1!2m1!1e2!2m1!1e3!3sIAE,lf:1,lf_ui:10&rlst=f#rlfi=hd:;si:13423092431246671662,l,ChVoaWRyb2VsZXRyaWNhIGNvbG9tYm9aLgoVaGlkcm9lbGV0cmljYSBjb2xvbWJvIhVoaWRyb2VsZXRyaWNhIGNvbG9tYm8,y,HteK5kOepuc;mv:[[-25.353267109399948,-49.228693637084966],[-25.5240969340061,-49.55347696228028]];tbs:lrf:!1m4!1u3!2m2!3m1!1e1!1m4!1u2!2m2!2m1!1e1!2m1!1e2!2m1!1e3!3sIAE,lf:1,lf_ui:10" style={{fontSize: 14}}>
                    {/* <FontAwesomeIcon icon={faWhatsapp}/>  */}
                    Av. Sete de Setembro, 4911 - Batel
                  </a>
                </Col>
                <Col lg={4} className="fones-container">
                  <div className="fones-loja">
                    <FontAwesomeIcon icon={faMapMarkerAlt}/>
                    <h3> Loja Champagnat </h3>
                  </div>
                  <a href="tel:+554130165253">
                    <FontAwesomeIcon icon={faPhoneAlt}/> 
                    (41) 3016-5253
                  </a>
                  <a target="_blank" rel="noopener noreferrer" href="https://www.google.com/search?q=hidroeletrica%20colombo&oq=hidroeletrica+colo&aqs=chrome.0.0l3j69i57j0j69i61j69i60j69i61.2731j0j9&sourceid=chrome&ie=UTF-8&sxsrf=ALeKk02_jiI6ysVBl2YNy4pGINvsd7R_qA:1598446965781&npsic=0&rflfq=1&rlha=0&rllag=-25418608,-49251284,5389&tbm=lcl&rldimm=18434280057180022201&lqi=ChVoaWRyb2VsZXRyaWNhIGNvbG9tYm9aLgoVaGlkcm9lbGV0cmljYSBjb2xvbWJvIhVoaWRyb2VsZXRyaWNhIGNvbG9tYm8&phdesc=lVtGgOUSI44&ved=2ahUKEwiTtdn89rjrAhWWILkGHRHtAGoQvS4wAXoECA0QMg&rldoc=1&tbs=lrf:!1m4!1u3!2m2!3m1!1e1!1m4!1u2!2m2!2m1!1e1!2m1!1e2!2m1!1e3!3sIAE,lf:1,lf_ui:10&rlst=f#rlfi=hd:;si:18434280057180022201,l,ChVoaWRyb2VsZXRyaWNhIGNvbG9tYm9aLgoVaGlkcm9lbGV0cmljYSBjb2xvbWJvIhVoaWRyb2VsZXRyaWNhIGNvbG9tYm8,y,lVtGgOUSI44;mv:[[-25.353236825376584,-49.228693637084966],[-25.524066649982732,-49.55347696228028],null,[-25.438682021703023,-49.39108529968262],12];tbs:lrf:!1m4!1u3!2m2!3m1!1e1!1m4!1u2!2m2!2m1!1e1!2m1!1e2!2m1!1e3!3sIAE,lf:1,lf_ui:10" style={{fontSize: 14}}>
                    {/* <FontAwesomeIcon icon={faWhatsapp}/>  */}
                    Av. Padre Anchieta, 1367 - Bigorrilho
                  </a>
                </Col>
              </Row>
            </Col>
        </section>

        <Footer/>

      </>
    );
  }
}

export default FaleConosco;
