import React from 'react';
import { Redirect, Link } from "react-router-dom";
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import {ApplicationState} from '../../store';
import {User, UserTypes} from '../../store/ducks/user/types';
import * as UserActions from '../../store/ducks/user/actions';
import {Param, ParamTypes} from '../../store/ducks/param/types';
import * as ParamActions from '../../store/ducks/param/actions';
import {Cart, CartTypes, ProductType} from '../../store/ducks/cart/types';
import * as CartActions from '../../store/ducks/cart/actions';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Api from '../../services/api';
import {Diversos} from '../../services/diversos';
import { Table, Button, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import Image from 'react-bootstrap/Image'

import './index.scss';

interface StateProps { 
  user: User;
  param: Param;
  cart: Cart;
} 

interface DispatchProps {
  doLogin(user: User): void;
  setParam(param: Param): void;
  drop(rowid: string): void;
  update(cart: ProductType): void;
}

interface OwnProps {
  item: any;
}

type Props = StateProps & DispatchProps & OwnProps;

class Checkout extends React.Component<Props> {

  api: any = null;
  state: any = null;

  constructor(props) {
    super(props);
    this.api = new Api();
    this.state = {
      redirect: null,
      isLoading: false,
      email: "",
      senha: "",
      hasError: false,
      hasErrorTitle: "",
      hasErrorMsg: "",
    }
  }

  componentDidMount () {
    const self = this; 
    // if (( self.props.user ) && ( self.props.user.status === true )) { // SE USUARIO ESTIVER LOGADO REDIRECIONA PARA OUTRA PAGINA
    //   self.setState({redirect: '/'});
    // }
  }

  private getCartTotal () {
    const self = this;
    let total = 0.00;
    for ( var i = 0; i < self.props.cart.produtos.length; i++ ) {
      total += self.props.cart.produtos[i].price * self.props.cart.produtos[i].qty;
    }
    return total;
  }

  private handleDropProduto (item: any) {
    const self = this;

    if ( !item )
      return;

    let confirmDrop = window.confirm(`Deseja realmente excluir o produto ${item.nome} do seu carrinho?`);

    if ( confirmDrop )
      self.props.drop(item.rowid);
  }

  render () {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }

    return (
      <>
        <Header/>

        <section className="content-fluid checkout-container">
          <h1 className="mt-2 mb-5"> Checkout </h1>
          {
            (this.props.cart.produtos.length <= 0) ? 
            <>
              <Row className="mx-0">
                <Col>
                  <div className="alert custom-alert"> Seu carrinho está vazio. Navegue por nossa loja e encontre seus produtos favoritos! </div>
                </Col>
              </Row>

              <Row className="my-3 mx-0">
                <Col>
                  <a href="/" target="_self" className="btn btn-link checkout-keep-shopping">
                    <FontAwesomeIcon icon={faArrowLeft} className="mr-2 text-link" /> 
                    Continuar comprando
                  </a>
                </Col>
              </Row>
            </>
            :
            <>
              <Table responsive="md" className="checkout-products-list">
                <thead className="table-head">
                  <tr>
                    {/* <th>#</th> */}
                    <th style={{minWidth: 200}}>Produto</th>
                    <th style={{minWidth: 80}} className="text-center">Preço</th>
                    <th className="text-center">Quantidade</th>
                    <th className="text-center">Subtotal</th>
                    <th></th>
                  </tr>
                </thead>

                <tbody className="table-body">
                  {
                    this.props.cart.produtos.map((row, index) => {
                      let optionEstoques: Array<number> = [];
                      let estoque = (row.estoque) ? row.estoque : 0;

                      for (var i = 1; i <= estoque; i++) {
                        optionEstoques.push(i);
                      }

                      return (<tr key={index}>
                        {/* <td>{index + 1}</td> */}
                          <td className="px-0 d-flex align-items-center">
                            <Image 
                              src={`${row.foto}`}
                              thumbnail 
                              className="checkout-item-img align-center d-none d-md-block" 
                            />
                            {row.nome}
                          </td>
                        {/* <td className="text-center align-center">R$ {Diversos.number_format(row.price,2,",","")}</td> */}
                        
                        <td className="text-center align-center">
                          {
                            ((row.priceOrigin) && (row.priceOrigin > row.price)) ? 
                            <>
                              <small style={{textDecoration: 'line-through'}}>De R$ ${Diversos.number_format(row.priceOrigin,2,",","")}</small>
                              <br/>
                              Por R$ ${Diversos.number_format(row.price,2,",","")}
                            </>
                            :
                            `R$ ${Diversos.number_format(row.price,2,",","")} `
                          }
                        </td>
                        
                        <td className="text-center align-center">
                          <select 
                            className="form-control text-center" 
                            name={`qty-item-${index}`} 
                            id={`qty-item-${index}`} 
                            onChange={(event) => {
                              this.props.update({
                                ...row,
                                qty: parseInt(event.target.value)
                              });
                            }}
                          >
                            {
                              optionEstoques.map((row2) => (<option value={row2} selected={row2 === row.qty}>{row2}</option>))
                            }
                          </select>
                        </td>
                        <td className="text-center align-center">R$ {Diversos.number_format((row.price * row.qty),2,",","")}</td>
                        <td className="text-right align-center">
                          <Button variant="link" onClick={this.handleDropProduto.bind(this, row)}>
                            <FontAwesomeIcon icon={faTrashAlt} className="cart-item-action color-modifier" title="Excluir item" /> 
                          </Button>
                        </td>
                      </tr>);
                    })
                  }
                </tbody>
              </Table>
              
              <Row className="mt-5 mb-5 mx-0 checkout-total">
                <Col xs={12} sm={6} className="total-container">
                  <h2> TOTAL </h2>
                </Col>
                <Col xs={12} sm={6} className="price-container">
                  <p> R$ {Diversos.number_format(this.getCartTotal(),2,",","")} </p>
                </Col>
              </Row>

              <Row className="mx-0">
                <Col sm={12} md={6} className="m-auto">
                  <a href="/" target="_self" className="btn btn-link checkout-keep-shopping">
                    <FontAwesomeIcon icon={faArrowLeft} className="mr-2 text-link" /> 
                    Continuar comprando
                  </a>
                </Col>
                <Col sm={12} md={6} className="purchase">
                  <a href={(( this.props.user ) && ( this.props.user.status === true )) ? `/checkout/entrega` : `/login`} className="btn cart-primary-action btn-lg text-white">
                    <FontAwesomeIcon icon={faShoppingCart} className="mr-2 text-white" /> 
                    Finalizar compra
                  </a>
                </Col>
              </Row>
            </>
          }
        </section>
        
        <Footer/>
      </>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.data,
  param: state.param.data,
  cart: state.cart.data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({...UserActions, ...ParamActions, ...CartActions}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Checkout);
