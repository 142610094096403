import React, { Component } from 'react';
import './index.scss';

import Header from '../../components/Header'
import Footer from '../../components/Footer'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Missao from '../../assets/Images/missao.png';
import Atendimento from '../../assets/Images/atendimento.png';
import Demanda from '../../assets/Images/demanda.png';
import Cadastro from '../../assets/Images/cadastro.png';
import Entrega from '../../assets/Images/entrega.png';
import logo from '../../assets/Images/logo.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';


class ComoComprar extends Component <{}> {

  state: any = {};

  constructor (props: any) {
    super(props);
  }

  render () {
    return (
      <>

        <Header/>

        <section className="institucional">
          <h1 className="mt-3"> <span className="heading-border"> Como Comprar </span> </h1>
          <article className="sobre-nos-texto">
            <p>
              
            </p>
          </article>
          
          <Row className="sobre-lojas">
            <Col xs={12} xl={12}>
              <p>Nosso mix de produtos é composto por 10 mil itens divididos em departamentos, para que seja mais fácil encontrar exatamente o que você precisa, com comodidade e qualidade garantidas.</p>
              <p>O  menu de busca por palavra é uma excelente opção.</p>
              <p>Também pelo menu por departamento, que leva às categorias de produtos até o item de desejo do consumidor.</p>
            </Col>
          </Row>
        </section>

        <Footer/>

      </>
    );
  }
}

export default ComoComprar;
