import React from 'react';
import './index.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram, faFacebookSquare, faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faPhoneAlt, faMapMarkerAlt, faEnvelope} from '@fortawesome/free-solid-svg-icons'
import logoFooter from '../../assets/Images/logo-footer.png'
import Api from '../../services/api';
import {Diversos} from '../../services/diversos';

// Import de Componentes Bootstrap
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import Button from 'react-bootstrap/Button'


class Footer extends React.Component<{}> {

  api: any = null;
  state: any = null;

  constructor(props) {
    super(props);

    this.api = new Api();

    this.state = {
      isLoading: false,
      hasError: false,
      hasErrorMsg: null,
      hasSuccess: false,
      hasSuccessMsg: null,
      email: null,
      menu: [],
      isLoadingMenu: true,
    }

    this.getMenu();
  }

  private async getMenu () {
    const self = this;

    self.setState({ isLoadingMenu: true });

    try {
      const { data } = await self.api.get('/menu/nivel1');

      if ( data.status === false ) {
        throw new Error('Nao foi possível recuperar menu');
      } else {
        let aux: any = [];

        for (var i = 0; i < data.msg.length; i++) {
          aux.push({
            codigo: data.msg[i].CDMENU,
            descricao: data.msg[i].DESCRICAO
          });
        }

        self.setState({ menu: aux });
      }

    } catch (e) {
      console.error(e);
      self.setState({ menu: [] });
    } finally {
      self.setState({ isLoadingMenu: false });
    }
  }

  private async handleNewsletterSubmit () {
    const self = this;

    if ( !self.state.email ) {
      self.setState({
        hasError: true,
        hasErrorMsg: "Formulário incompleto, por favor insira seu E-mail."
      });

      return false;
    }

    let param = {
      email: self.state.email
    }

    self.setState({isLoading: true, hasError: false, hasSuccess: false});

    try {
      const {data} = await self.api.post("/customer/newsletter", param);

      if ( !data.status ) {
        throw new Error('Não foi possível registrar seu e-mail, por favor tente novamente.');
      } else {
        self.setState({hasSuccess: true, hasSuccessMsg: "Inscrição feita com sucesso!"});
      }

    } catch (e) {
      console.error(e);
      self.setState({hasError: true, hasErrorMsg: "Não foi possível completar a inscrição!"});
    } finally {
      self.setState({isLoading: false});
    }
  }

  
  render () {
    return (
      <>
        <footer>
          <Row className="my-5 address-container">
            <Col lg={2}>
              <h4> NOSSAS LOJAS </h4>
            </Col>
            <Col lg={3} className="info">
              <p><strong>BATEL</strong></p>
              <span className="mt-2">
                <FontAwesomeIcon icon={faMapMarkerAlt}/> 
                Av. Sete de Setembro, 4911 - Batel
                <br/>
                <a href="https://www.google.com.br/maps/place/HIDROEL%C3%89TRICA+COLOMBO/@-25.4450392,-49.2877784,17z/data=!3m1!4b1!4m5!3m4!1s0x94dce477fadd9819:0xba48677297b13f2e!8m2!3d-25.4450392!4d-49.2855897" target="_blank"> ver mapa </a>
              </span>
              <span className="mt-4 address">
                <div>
                  <FontAwesomeIcon icon={faPhoneAlt}/> 
                  <h5 className="mr-4">(41) 3026-3233</h5>
                </div>
              </span>
            </Col>
            <Col lg={3} className="info">
              <p><strong>CHAMPAGNAT</strong></p>
              <span className="mt-2">
                <FontAwesomeIcon icon={faMapMarkerAlt}/> 
                Av. Padre Anchieta, 1367 - Bigorrilho
                <br/>
                <a href="https://www.google.com.br/maps/place/HIDROEL%C3%89TRICA+COLOMBO/@-25.4450389,-49.2943445,15z/data=!4m8!1m2!2m1!1shidroel%C3%A9trica+colombo!3m4!1s0x94dce3f1b4a17195:0xffd3b80b024865b9!8m2!3d-25.4305433!4d-49.2926375" target="_blank"> ver mapa </a>
              </span>
              <span className="mt-4 address">
                <div>
                  <FontAwesomeIcon icon={faPhoneAlt}/> 
                  <h5 className="mr-4">(41) 3016-5253</h5>
                </div>
              </span>
            </Col>
            <Col lg={3} className="info">
              <p><strong>SANTA CÂNDIDA</strong></p>
              <span className="mt-2">
                <FontAwesomeIcon icon={faMapMarkerAlt}/> 
                Rua Theodoro Makiolka, 885 - Santa Cândida
                <br/>
                <a href="https://www.google.com.br/maps/place/HIDROELETRICA+COLOMBO/@-25.3715,-49.2333077,17z/data=!3m1!4b1!4m5!3m4!1s0x94dce727b0df7b89:0xb8ad7bc32a7454d7!8m2!3d-25.3715!4d-49.231119" target="_blank"> ver mapa </a>
              </span>
              <span className="mt-4 address">
                <div>
                  <FontAwesomeIcon icon={faPhoneAlt}/> 
                  <h5 className="mr-4">(41) 3356-3233</h5>
                </div>
              </span>
            </Col>
          </Row>
          <Row className="my-5 newsletter-container">
            <Col xs={12} className="search-bar">

              {
                this.state.hasError &&
                <div className="alert newsletter-error">
                  {this.state.hasErrorMsg}
                </div>
              }

              {
                this.state.hasSuccess &&
                <div className="alert text-success">
                  {this.state.hasSuccessMsg}
                </div>
              }

              <p> <strong> INSCREVA-SE PARA RECEBER NOSSAS OFERTAS E PROMOÇÕES </strong> </p>
              <InputGroup className="mb-3">
                <FormControl
                onChange={(event) => this.setState({email: event.target.value})} 
                className="search-box-input"
                placeholder="Insira seu e-mail"
                aria-label="Busque aqui o que vocÊ precisa"
                aria-describedby="Barra de pesquisa"/>
                <InputGroup.Append>
                  <Button variant="primary" style={{borderRadius: 0}} onClick={this.handleNewsletterSubmit.bind(this)}>
                    INSCREVA-SE
                  </Button>
                </InputGroup.Append>
              </InputGroup>
            </Col>
          </Row>
          <Row className="w-100 mb-5 footer-content">
            <Col xs={10} className="stores">
              <Row>
                <Col md={4} lg={3} className="info">
                  <p> ATENDIMENTO </p>
                  <a href="/meus-pedidos"> Meus pedidos </a>
                  <a href="/cadastro" target="_self"> Minha conta </a>
                  <a href="/como-comprar" target="_self"> Como comprar </a>
                  <a href="/modo-de-entrega" target="_self"> Frete e envio </a>
                  <a href="/politica-de-troca-e-devolucao" target="_self"> Politica de trocas e devoluções </a>
                  <a href="/perguntas-frequentes" target="_self"> Perguntas frequentes </a>
                  <a href="mailto:sac@hidroeletrica.com"> <FontAwesomeIcon icon={faEnvelope} style={{fontSize: 14}}/> sac@hidroeletrica.com </a>
                  <a href="/fale-conosco" target="_self"> Entre em contato </a>
                </Col>
                <Col md={4} lg={3} className="info">
                  {
                    this.state.isLoadingMenu ? 
                    <i className="fas fa-spin fa-spinner"></i>
                    :
                    this.state.menu.length <= 0 ?
                    <></>
                    :
                    <>
                      <p> DEPARTAMENTOS </p>
                      <ul>
                        {
                          this.state.menu.map((row, index) => (
                            <li key={index}> <a href={`/departamento/${Diversos.toSeoUrl(row.descricao)}`} style={{textTransform: 'capitalize'}}> {row.descricao.toLowerCase()} </a></li>
                          ))
                        }
                      </ul>
                    </>
                  }
                  
                </Col>
                <Col md={4} lg={3} className="info">
                  <p> SOBRE NÓS </p>
                  <a href="/empresa" target="_self"> Quem somos </a>
                  <a href="/fale-conosco" target="_self"> Fale Conosco </a>
                  <a> Blog </a>
                  <a href="/dicas-hc"> Dicas HC </a>
                </Col>
                <Col md={4} lg={3} className="info">
                  <p> SEGURANÇA </p>
                  <a> E-bit </a>
                  <a> Pague Seguro </a>
                </Col>
              {/* </Row> */}
              {/* <Row className="mt-5"> */}
                  <Col md={4} lg={3} className="info">
                    <p> ENTREGA </p>
                    <a> Sedex </a>
                    <a> Total </a>
                    <a> Pac </a>
                  </Col>
                  <Col md={4} lg={3} className="info">
                    <p> SOCIAL </p>
                    <ul>
                      <li> <a href="https://www.instagram.com/hidroeletrica_colombo/" target="_blank" rel="noopener noreferrer"> Instagram </a> </li>
                      <li> <a href="https://www.facebook.com/hidroeletrica.colombo" target="_blank" rel="noopener noreferrer"> Facebook </a> </li>
                      <li> <a href="https://api.whatsapp.com/send?phone=554133563233&text=Olá!%20Gostaria%20de%20sanar%20uma%20dúvida..." target="_blank" rel="noopener noreferrer"> Whatsapp </a> </li>
                    </ul>
                  </Col>
                </Row>
            </Col>
          </Row>
          <Row className="w-100 mt-5">
            <p className="copyright"> Todos os direitos reservados © 2020 </p>
          </Row>
        </footer>
      </>
    );
  }
}

export default Footer;