import React, { Component } from 'react';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';
import { BrowserRouter as Router, Route, Link, RouteComponentProps, Switch, Redirect } from "react-router-dom";
import {store, persistor} from '../store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './App.scss';

import Home from "./Home";
import Produto from "./Produto";
import ResetSenha from "./ResetSenha";
import Login from "./Login";
import Cadastro from "./Cadastro";
import Departamento from "./Departamento";
import Promocao from "./Promocao";
import Empresa from "./Empresa";
import ComoComprar from "./ComoComprar";
import Frete from "./Frete";
import TrocaDevolucao from "./TrocaDevolucao";
import Lojas from "./Lojas";
import FaleConosco from "./FaleConosco";
import OfertasDoMes from "./OfertasDoMes";
import Pesquisa from "./Pesquisa";
import Checkout from "./Checkout";
import CheckoutPagamento from "./CheckoutPagamento";
import CheckoutFim from "./CheckoutFim";
import NoMatchPage from "./NoMatchPage";
import DicasHC from "./DicasHC"
import PerguntasFrequentes from "./PerguntasFrequentes"
import MeusPedidos from "./MeusPedidos";

class App extends Component <{}> {

  state: any = {};

  constructor (props: any) {
    super(props);
  }

  render () {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router>

            <Switch>
              {/* DECLARA AS ROTAS USADAS NO PROJETO */}
              <Route path="/" exact component={Home}/>
              <Route path="/login" exact component={Login}/>
              <Route path="/cadastro" exact component={Cadastro}/>
              <Route path="/recuperar-senha" exact component={ResetSenha}/>
              <Route path="/meus-pedidos" exact strict component={MeusPedidos}/>
              <Route path="/departamento/:menu1" exact strict component={Departamento}/>
              <Route path="/departamento/:menu1/:menu2" exact strict component={Departamento}/>
              <Route path="/departamento/:menu1/:menu2/:menu3" exact strict component={Departamento}/>
              <Route path="/busca/:termo" exact strict component={Pesquisa}/>
              <Route path="/promocoes" exact strict component={Promocao}/>
              <Route path="/empresa" exact strict component={Empresa}/>
              <Route path="/lojas" exact strict component={Lojas}/>
              <Route path="/fale-conosco" exact strict component={FaleConosco}/>
              <Route path="/politica-de-troca-e-devolucao" exact strict component={TrocaDevolucao}/>
              <Route path="/ofertas-do-mes" exact strict component={OfertasDoMes}/>
              <Route path="/checkout" exact strict component={Checkout}/>
              <Route path="/dicas-hc" exact strict component={DicasHC}/>
              <Route path="/checkout/entrega" exact strict component={CheckoutPagamento}/>
              <Route path="/checkout/fim" exact strict component={CheckoutFim}/>
              <Route path="/como-comprar" exact strict component={ComoComprar}/>
              <Route path="/modo-de-entrega" exact strict component={Frete}/>
              <Route path="/perguntas-frequentes" exact strict component={PerguntasFrequentes}/>
              <Route path="/404" exact stric component={NoMatchPage}/>
              <Route path="/:nome" exact strict component={Produto}/>
            </Switch>
            
          </Router>
        </PersistGate>
      </Provider>
    );
  }
}

export default App;
