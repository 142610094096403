import React from 'react';
import { Redirect, Link } from "react-router-dom";
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import {ApplicationState} from '../../store';
import {User, UserTypes} from '../../store/ducks/user/types';
import * as UserActions from '../../store/ducks/user/actions';
import {Param, ParamTypes} from '../../store/ducks/param/types';
import * as ParamActions from '../../store/ducks/param/actions';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Api from '../../services/api';
import './index.scss';

import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'



interface StateProps { 
  user: User;
  param: Param;
} 

interface DispatchProps {
  doLogin(user: User): void;
  setParam(param: Param): void;
}

interface OwnProps {}

type Props = StateProps & DispatchProps & OwnProps;

class Login extends React.Component<Props> {

  api: any = null;
  state: any = null;

  constructor(props) {
    super(props);
    this.api = new Api();
    this.state = {
      redirect: null,
      isLoading: false,
      email: "",
      senha: "",
      hasError: false,
      hasErrorTitle: "",
      hasErrorMsg: "",
    }
  }

  componentDidMount () {
    const self = this;
    
    if (( self.props.user ) && ( self.props.user.status === true )) { // SE USUARIO ESTIVER LOGADO REDIRECIONA PARA OUTRA PAGINA
      self.setState({redirect: '/'});
    }
  }

  /**
   * FAZ TRATAMENTO DA SUBMISSAO FORM DE LOGIN
   */
  private async handleSubmit () {
    const self = this;

    self.setState({
      isLoading: true,
      hasErrorTitle: false,
      hasErrorMsg: "",
    });

    let param = {
      email: self.state.email,
      senha: self.state.senha
    }

    try {
      const {data} = await self.api.post("/customer/login", param);
      
      console.log(data);

      if (!data.status) {
        throw new Error(data.msg);
      } else {
        self.props.doLogin({
          codigo: data.msg.codigo,
          nome: data.msg.nome,
          email: data.msg.email,
          cpf: data.msg.cpf,
          status: true,
          avatar: '',
          token: null,
        });

        self.setState({
          redirect: '/',
          hasError: false,
          hasErrorTitle: '',
          hasErrorMsg: ""
        });
      }

    } catch (e) {
      // console.error(e);
      self.setState({
        hasError: true,
        hasErrorTitle: 'Login inválido',
        hasErrorMsg: "Não foi possível efetuar login, por favor tente novamente",
      })
    } finally {
      self.setState({ isLoading: false });
    }
  }

  render () {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }

    return (
        <>
        <Header/>

        <section className="login-container" id="unlogged-user-login">
          <div className="form-container">
            {
              (this.state.isLoading) ? 
              <span>Carregando, aguarde...</span>
              :
              <>
                <h1 className="mt-5"> <span className="heading-border"> Login </span> </h1>
                <div className="input-container" id="unlogged-user-input">

                  {
                    this.state.hasError &&
                    <span>
                      <p className="login-error-title">{this.state.hasErrorTitle}</p>
                      <p className="login-error-msg">{this.state.hasErrorMsg}</p>
                    </span>
                  }

                  <Form className="d-flex w-100 mt-5">
                    <Col>
                      <Form.Row>
                        <Form.Group as={Col} controlId="formGridEmail" className="mb-3">
                          <Form.Label className="ml-3"> Seu Email</Form.Label>
                          <Form.Control type="email" className="user-input" value={this.state.email}
                          onChange={(event) => this.setState({email: event.target.value})}/>
                        </Form.Group>
                      </Form.Row>
                      <Form.Row>
                        <Form.Group as={Col} controlId="formGridPassword">
                          <Form.Label className="ml-3"> Sua Senha </Form.Label>
                          <Form.Control type="password" className="user-input" value={this.state.senha}
                          onChange={(event) => this.setState({senha: event.target.value})} />
                        </Form.Group>
                      </Form.Row>
                      <Row>
                        <Col>
                          <a href="/nova_senha" className="ml-3 forgot-pswd">Esqueceu sua senha?</a>
                        </Col>
                      </Row>
                    </Col>
                    
                  </Form>
                  
                </div>
                <input type="submit" value="Entrar" title="Entrar" onClick={this.handleSubmit.bind(this)}></input>
                <h3> <span className="font-size-16"> Não tem uma conta? </span> <a href="/cadastro"> Cadastre-se </a> </h3>
              </>
            }
          </div>
        </section>
        
        <Footer/>
        </>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.data,
  param: state.param.data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({...UserActions, ...ParamActions}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Login);
