import React, { Component } from 'react';
import './index.scss';

import { Redirect, Link } from "react-router-dom";
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import { compose } from "recompose";
import moment from "moment";
import 'moment/locale/pt-br';
import {Helmet} from "react-helmet";
import {ApplicationState} from '../../store';
import {User, UserTypes} from '../../store/ducks/user/types';
import * as UserActions from '../../store/ducks/user/actions';
import {Param, ParamTypes} from '../../store/ducks/param/types';
import * as ParamActions from '../../store/ducks/param/actions';
import Api from '../../services/api';
import Slider from "react-slick";


// import de Componentes
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Product from '../../components/Product';
import SmallBanner from '../../components/SmallBanner';
import BigBanner from '../../components/BigBanner';

import teste_1 from '../../assets/Images/image-3.png'
import teste_2 from '../../assets/Images/image-3-hover.jpg'

// import de Imagens/SVG
import bannerBig from '../../assets/Images/banner.png'

import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import Carousel from 'react-bootstrap/Carousel'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCartPlus } from '@fortawesome/free-solid-svg-icons'


interface StateProps { 
  user: User;
  param: Param;
}

interface DispatchProps {
  doLogin(user: User): void;
  setParam(param: Param): void;
}

interface OwnProps {}

class Home extends Component<StateProps & DispatchProps & OwnProps> {

  api: any = null;
  state: any = null;

  constructor (props) {
    super(props);
    this.api = new Api();
    this.state = {
      destaqueHomeLimit: 1,
      destaqueHomeIsLoading: true,
      destaqueHomeProcuts: [],
      promocaoHomeLimit: 1,
      promocaoHomeIsLoading: true,
      promocaoHomeProcuts: [],
      isLoadingBanner: false,
      banners: [],
      isLoadingBannerRodape: false,
      bannerRodape: [],
      isLoadingBannerDica: false,
      bannerDica: []
    };
  }

  componentDidMount () {
    this.getDestaqueHome();
    this.getPromocaoHome();
    this.getBannersHome();
    this.getBannerRodape();
    this.getBannerDica();
  }

  private _henderSEOTags () {
    return (
      <Helmet>
        <title>{process.env.REACT_APP_TITLE}</title>
        <link rel="canonical" href={process.env.REACT_APP_URL} />
        <meta name="url" content={process.env.REACT_APP_URL} />
        <meta name="robots" content="index"/>
        <meta name="description" content={`Promoções exclusivas somente em nossa loja online - ${process.env.REACT_APP_TITLE}`} />
        <meta name="autor" content="TecWorks" />
        <meta name="company" content="TecWorks" />
        <meta name="revisit-after" content="2" />

        {/* LD+JSON  */}
        <script type="application/ld+json">
          {`
            [{
                "@context": "http://schema.org",
                "@type": "WebSite",
                "name": "${process.env.REACT_APP_TITLE}",
                "alternateName": "Hidroeletrica",
                "url": "${process.env.REACT_APP_URL}",
                "potentialAction": {
                    "@type": "SearchAction",
                    "target": "${process.env.REACT_APP_URL}/busca/{search_term_string}",
                    "query-input": "required name=search_term_string" 
                }
            },
            {
                "@context": "http://schema.org",
                "@type": "Organization",
                "url": "${process.env.REACT_APP_URL}",
                "logo": "${require(`../../assets/Images/logo.png`)}",
                "contactPoint": [{
                    "@type": "ContactPoint",
                    "telephone": "+55 (41) 3027-3233",
                    "contactType": "sales"
                }]
            },
            {
                "@context": "http://schema.org",
                "@type": "Person",
                "name": "${process.env.REACT_APP_TITLE}",
                "url": "${process.env.REACT_APP_URL}",
                "sameAs": [
                    "https://www.facebook.com/",
                    "https://www.instagram.com/"
                ]
            }]
          `}
        </script>

        {/* TWITTER CARDS */}
        <meta name="twitter:card" content="app" />
        <meta name="twitter:site" content={`${process.env.REACT_APP_URL}`} />
        <meta name="twitter:title" content={`${process.env.REACT_APP_TITLE}`} />
        <meta name="twitter:description" content={`Promoções exclusivas para nossa loja virtual - ${process.env.REACT_APP_TITLE}`} />
        <meta name="twitter:image" content={require(`../../assets/Images/logo.png`)} />

        {/* FACEBOOK CARDS */}
        <meta property="fb:app_id" content="270229570743012" />
        <meta property="og:site_name" content={`${process.env.REACT_APP_TITLE}`}/>
        <meta property="og:locale" content="pt_BR" />
        <meta property="og:type" content="website"/>
        <meta property="og:url" content={`${process.env.REACT_APP_URL}`}/>
        <meta property="og:title" content={`${process.env.REACT_APP_TITLE}`}/>
        <meta property="og:image" content={require(`../../assets/Images/logo.png`)} />
        <meta property="og:description" content={`Promoções exclusivas para nossa loja virtual - ${process.env.REACT_APP_TITLE}`}/>
        {/* <meta property="og:product:price:amount" content="'. $facebook->product_price_amount .'">
        <meta property="og:product:price:currency" content="'. $facebook->product_price_currency .'">
        <meta property="og:product:brand" content="'. $facebook->product_brand .'">
        <meta property="og:product:condition" content="'. $facebook->product_condition .'">
        <meta property="og:product:availability" content="'. $facebook->product_availability .'"> */}
      </Helmet>
    );
  }

  private async getDestaqueHome () {
    const self = this;
    
    self.setState({destaqueHomeIsLoading: true});

    let param = {};

    try {
      const {data} = await self.api.post(`/product/home/${self.state.destaqueHomeLimit}`, param);

      if ( data.status === false ) {
        throw new Error(`Nenhum produto localizado na home: ${data.msg}`);
      } else {
        self.setState({destaqueHomeProcuts: data.msg.data})
      }

    } catch (e) {
      console.error(e);
    } finally {
      self.setState({destaqueHomeIsLoading: false});
    }
  } 

  private async getPromocaoHome () {
    const self = this;
    
    self.setState({promocaoHomeIsLoading: true});

    let param = {};

    try {
      const {data} = await self.api.post(`/product/promocao/${self.state.promocaoHomeLimit}`, param);

      if ( data.status === false ) {
        throw new Error(`Nenhum produto localizado na promocao: ${data.msg}`);
      } else {
        self.setState({promocaoHomeProcuts: data.msg.data})
      }

    } catch (e) {
      console.error(e);
    } finally {
      self.setState({promocaoHomeIsLoading: false});
    }
  } 

  private async getBannersHome () {
    const self = this;
    
    self.setState({isLoadingBanner: true});

    try {
      const {data} = await self.api.get(`/banners?tipo=1`);

      if ( data.status === false ) {
        throw new Error(`Nenhum banner localizado: ${data.msg}`);
      } else {
        self.setState({banners: data.msg})
      }

    } catch (e) {
      console.error(e);
    } finally {
      self.setState({isLoadingBanner: false});
    }
  } 

  private async getBannerRodape () {
    const self = this;
    
    self.setState({isLoadingBannerRodape: true});

    try {
      const {data} = await self.api.get(`/banners?tipo=3`);

      if ( data.status === false ) {
        throw new Error(`Nenhum banner localizado: ${data.msg}`);
      } else {
        self.setState({bannerRodape: data.msg})
      }

    } catch (e) {
      console.error(e);
    } finally {
      self.setState({isLoadingBannerRodape: false});
    }
  } 

  private async getBannerDica () {
    const self = this;
    
    self.setState({isLoadingBannerDica: true});

    try {
      const {data} = await self.api.get(`/banners?tipo=2`);

      if ( data.status === false ) {
        throw new Error(`Nenhum banner localizado: ${data.msg}`);
      } else {
        self.setState({bannerDica: data.msg})
      }

    } catch (e) {
      console.error(e);
    } finally {
      self.setState({isLoadingBannerDica: false});
    }
  } 

  render () {
    // if ( this.props.user.status === false ) {
    //   return <Redirect to='/login' />;
    // }

    return (
      <>
        {this._henderSEOTags()}

        <Header/>

        <section className="banner-slider">
          {
            this.state.isLoadingBanner === true ?
            <div className="row">
              <div className="col-12 text-center">
                <i className="fas fa-spin fa-spinner"></i>
              </div>
            </div>
            :
            this.state.banners.length <= 0 ?
            <></>
            :
            <Carousel indicators={false}>
              {
                this.state.banners.map((row, index) => (
                  <Carousel.Item key={index}>
                    <img
                      className="d-block w-100"
                      src={`http://hidroeletrica.painel.tecworks.com.br/${row.path}`}
                      alt={row.titulo}
                    />
                  </Carousel.Item>
                ))
              }
            </Carousel>
          }
        </section>
          
        {
          ((this.state.destaqueHomeIsLoading === true) || (this.state.destaqueHomeProcuts.length > 0)) &&
          <section className="mt-5 vitrine">
            <h2> <span className="heading-border"> Destaques </span> do mês de {moment().format('MMMM')} </h2>

            {
              this.state.destaqueHomeIsLoading ?
              <Row>
                <Col xs={12} className="text-center py-0 px-0">
                  <i className="fas fa-spin fa-spinner fa-3x my-3 mx-auto"></i>
                </Col>
              </Row>
              :
              <Row className="products-wrapper w-100 mx-0">
                  {
                    this.state.destaqueHomeProcuts.map((row,index) => (
                      // <Col md={6} lg={2}>
                      <Col xs={12} sm={6} lg={4} xl={3} className="my-5 carousel-procuts">
                        <Product 
                          imageNormal={
                            ((row.FOTOS) && (row.FOTOS.length > 0)) ? 
                            `${process.env.REACT_APP_BASE_URL_PICTURE}/${row.FOTOS[0].link}` 
                            : 
                            `${process.env.REACT_APP_BASE_URL_PICTURE}/produto-sem-imagem.png`
                          }
                          imageOver={
                            ((row.FOTOS) && (row.FOTOS.length > 0)) ? 
                              (row.FOTOS.length > 1) ? 
                              `${process.env.REACT_APP_BASE_URL_PICTURE}/${row.FOTOS[1].link}` 
                              : 
                              `${process.env.REACT_APP_BASE_URL_PICTURE}/${row.FOTOS[0].link}`
                            :
                            `${process.env.REACT_APP_BASE_URL_PICTURE}/produto-sem-imagem.png`
                          } 
                          item={row}
                        />
                      </Col>
                    ))
                  }
                {/* </Col> */}
              </Row>
            }
          </section>
        }

        <section className="mini-banners">
          {
            this.state.isLoadingBannerDica === true ?
            <div className="row">
              <div className="col-12 text-center">
                <i className="fas fa-spin fa-spinner"></i>
              </div>
            </div>
            :
            this.state.bannerDica.length <= 0 ?
            <></>
            :
            <BigBanner url={`http://hidroeletrica.painel.tecworks.com.br/${this.state.bannerDica[0].path}`}/>
          }
          {/* <SmallBanner/> */}

          {
            this.state.isLoadingBannerRodape === true ?
            <div className="row">
              <div className="col-12 text-center">
                <i className="fas fa-spin fa-spinner"></i>
              </div>
            </div>
            :
            this.state.bannerRodape.length <= 0 ?
            <></>
            :
            <BigBanner url={`http://hidroeletrica.painel.tecworks.com.br/${this.state.bannerRodape[0].path}`}/>
          }
        </section>

        <Footer/>

      </>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.data,
  param: state.param.data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({...UserActions, ...ParamActions}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Home);
