import React from 'react';
import './index.scss';

import Minibanner2 from '../../assets/Images/banner-homeoffice.png'

const BigBanner = (props: any) => {

  if ( !props.url )
    return <></>;

  return (
    <>
      <div className="big-banner">
        <img src={props.url}/>
      </div>
    </>
  );
}

export default BigBanner