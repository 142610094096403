import React, {Component} from 'react';
import './index.scss';

import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import {ApplicationState} from '../../store';
import {Cart, CartTypes, ProductType} from '../../store/ducks/cart/types';
import * as CartActions from '../../store/ducks/cart/actions';
import {Param, ParamTypes} from '../../store/ducks/param/types';
import * as ParamActions from '../../store/ducks/param/actions';
import {Diversos} from '../../services/diversos';
import './index.scss';
import { classDeclaration } from '@babel/types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCartPlus } from '@fortawesome/free-solid-svg-icons'
import { faBan } from '@fortawesome/free-solid-svg-icons'

import moment from "moment";
import 'moment/locale/pt-br';

import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import Image from 'react-bootstrap/Image'
import Container from 'react-bootstrap/Container'


  interface StateProps { 
    cart?: Cart;
    param: Param;
  } 

  interface DispatchProps {
    add(cart: ProductType): void;
    update(cart: ProductType): void;
    drop(rowid: string): void;
    setParam(data: Param): void;
  }


  interface OwnProps {
    imageOver: any,
    imageNormal: any,
    item?: any;
  }

  type Props = StateProps & DispatchProps & OwnProps;

  class Product extends Component<Props> {

    state:any

    constructor(props) {
      super(props);
      this.state = {
        imgSrc: this.props.imageNormal
      }
    }

    componentDidMount() {
    }
  
    handleMouseOver() {
      this.setState({
        // imgSrc: this.props.imageOver
        imgSrc: this.props.imageNormal
      });
    }
    
    handleMouseOut() {
      this.setState({
        imgSrc: this.props.imageNormal
      });
    }

    private async handleAddCart (event) {
      const self = this;
      event.preventDefault();

      let preco = self.props.item.PRECO;
      let precoOriginal = self.props.item.PRECO;

      if ((self.props.item.PREPRO > 0) && (self.props.item.PREPRO < self.props.item.PRECO) && (moment(self.props.item.INIPRO) <= moment()) && (moment(self.props.item.FIMPRO) >= moment())) {
        preco = this.props.item.PREPRO;
      }

      self.props.add({
        codigo: self.props.item.CODIGO,
        nome: self.props.item.NOME,
        priceOrigin: precoOriginal,
        price: preco,
        qty: 1,
        foto: self.props.imageNormal,
        estoque: self.props.item.ESTOQUE,
        peso: (!self.props.item.PESO) ? 0.01 : self.props.item.PESO,
      });
      self.props.setParam({ ...self.props.param, cartOpened: true });
    }

    render () {

      return (
        <>
          
        <a
          href={`/${Diversos.toSeoUrl(this.props.item.NOME)}`}
          target="_self"
          onMouseOver={this.handleMouseOver.bind(this)}
          onMouseOut={this.handleMouseOut.bind(this)}
          className="mb-5 product-container" title="Ver detalhes do produto">
            <Row className="w-100 img-wrapper">
              <Image src={this.state.imgSrc} className="product-img" alt=""/>
            </Row>
            <Row className="w-100 product-info">
              <Row className="product-name">
                <Col xs={12} className="p-0">
                <p> {Diversos.captalize(this.props.item.NOME.toLowerCase())} </p>
                {/* // PARA TESTAR ESTÁTICO, E COMENTE A LINHA ANTERIOR E DESCOMENTE A SEGUINTE
                <p> Parafuso 5mm aço inox caixa com 10 </p> */}
                </Col>
              </Row>
              <Row className="price-and-cart">
                <Col xs={8} className="rmv-padding">
                  
                {
                  // PARA TESTAR ESTÁTICO, COMENTE O BLOCO ENTRE CHAVES
                  ((this.props.item.PREPRO > 0) && (this.props.item.PREPRO < this.props.item.PRECO) && (moment(this.props.item.INIPRO) <= moment()) && (moment(this.props.item.FIMPRO) >= moment())) ? 
                  <>
                    <p className="product-price-alt"> R$ {Diversos.number_format(this.props.item.PRECO,2,",","")} </p>
                    <p className="product-price"> {Diversos.number_format(this.props.item.PREPRO,2,",","")} </p> 
                  </>
                  :
                  <p className="product-price"> {Diversos.number_format(this.props.item.PRECO,2,",","")} </p>
                }
                
                  {/* PARA TESTAR ESTÁTICO, DESCOMENTE ESSE BLOCO
                  <p className="product-price-alt"> R$ 19,90 </p>
                  <p className="product-price"> R$ 9,90 </p>  */}
                </Col>
                <Col xs={4} className="d-flex flex-end rmv-padding">
                  {
                    // PARA TESTAR ESTÁTICO, COMENTE ESSA CONDIÇÃO
                    (parseFloat(this.props.item.ESTOQUE) <= 0) ? 
                    <FontAwesomeIcon icon={faBan} className="add-to-cart" title="Produto sem estoque"/>
                    :
                    <FontAwesomeIcon icon={faCartPlus} onClick={this.handleAddCart.bind(this)} className="add-to-cart" title="Adicionar ao carrinho"/>
                  }
                </Col>
              </Row>
            </Row>
          {/* <div onMouseOver={this.handleMouseOver.bind(this)}
          onMouseOut={this.handleMouseOut.bind(this)} className="product-container">
            <span className="img-wrapper">
              <img src={this.state.imgSrc} className="product-img" alt=""></img>    
            </span>
            <div className="product-info">
              <p className="product-name"> {this.props.item.NOME} </p>
              <div className="price-and-cart">
                <p className="product-price"> R$ {Diversos.number_format(this.props.item.PRECO,2,",","")} </p>
                <FontAwesomeIcon icon={faCartPlus} onClick={this.handleAddCart.bind(this)} className="add-to-cart" title="Adicionar ao carrinho"/>
              </div>
            </div>
          </div> */}
        </a>

        </>
      );
    }
  }

const mapStateToProps = (state: ApplicationState) => ({
  cart: state.cart.data,
  param: state.param.data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({...CartActions, ...ParamActions}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Product);