import React from 'react';
import './index.scss';

import { Redirect } from "react-router-dom";
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import {ApplicationState} from '../../store';
import {User} from '../../store/ducks/user/types';
import * as UserActions from '../../store/ducks/user/actions';
import {Param} from '../../store/ducks/param/types';
import * as ParamActions from '../../store/ducks/param/actions';
import {Cart, ProductType} from '../../store/ducks/cart/types';
import * as CartActions from '../../store/ducks/cart/actions';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import DetailsItem from '../../components/DetailsItem';
import UserSidebar from '../../components/UserSidebar';
import Api from '../../services/api';
import {Diversos} from '../../services/diversos';
import { Table, Button, Row, Col, Dropdown, FormControl, Modal } from 'react-bootstrap';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faFilePdf } from '@fortawesome/free-solid-svg-icons';


interface StateProps { 
  user: User;
  param: Param;
  cart: Cart;
} 

interface DispatchProps {
  doLogin(user: User): void;
  setParam(param: Param): void;
  drop(rowid: string): void;
  update(cart: ProductType): void;
}

interface OwnProps {
  item: any;
}

type Props = StateProps & DispatchProps & OwnProps;

class MeusPedidos extends React.Component<Props> {

  api: any = null;
  state: any = null;

  constructor(props) {
    super(props);
    this.api = new Api();
    this.state = {
      modalDetails: false,
      accordionCollapse: false,
      statusPedido: "",
      page: 1,
      total: 1,
      perPage: 1,
      lastPage: 1,
      pedidos: [],
      isLoading: true,
      isLoadingItems: false,
      pedidoCurrent: null,
      pedidoCurrentItems: null,
    }
  }

  componentDidMount () {
    const self = this; 

    if (( !self.props.user ) || ( self.props.user.status === false )) { 
      self.setState({redirect: '/login'});
      return -1;
    }

    self.getOrders();
  }

  private async getOrders () {
    const self = this;

    self.setState({isLoading: true});

    let param = {
      cliente: self.props.user.codigo,
      page: self.state.page
    }

    try {
      const {data} = await self.api.post(`/orders`, param);

      if ( !data.status ) {
        throw new Error(`Nenhum pedido localizado: ${data.msg}`);
      } else {
        self.setState({
          pedidos: data.msg.data,
          page: data.msg.page,
          perPage: data.msg.perPage,
          lastPage: data.msg.lastPage,
          total: data.msg.total,
        });
      }

    } catch (e) {
      console.error(e);

    } finally {
      self.setState({isLoading: false});
    }
  }

  private async getOrderItems (pedido: number) {
    const self = this;

    self.setState({isLoadingItems: true});

    try {
      const {data} = await self.api.get(`/order/${pedido}/items`);

      if ( data.status === false ) {
        throw new Error('Nenhum produto encontrado');
      } else {
        self.setState({
          pedidoCurrentItems: data.msg
        });
      }

    } catch (e) {
      console.error(e);
      self.setState({pedidoCurrentItems: []});
    } finally {
      self.setState({isLoadingItems: false});
    }
  }

  // Impede propagação do clique na div parent
  private handleChildClick (e, row) {
    this.setState({modalDetails: !this.state.modalDetails, pedidoCurrent: row}, this.getOrderItems.bind(this, row.PEDIDO))
  }

  // Controla classe aplicada ao status do pedido na tela
  private orderStatus (s) {
    if (s === 8) {
      return 'pedido-cancelado';
    }

    else if (s === 6) {
      return 'pedido-concluido';
    }

    else if (s === 5) {
      return 'pedido-enviado';
    }
  }

  private getTotalPedido (pedido: any) {
    let total = 0.00;

    for ( var i = 0; i < pedido.itens.length; i++ ) {
      total += pedido.itens[i].QTD * pedido.itens[i].VALOR;
    }

    return total;
  }

  render () {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }

    return (
      <>
        <Header/>

        <section className="meus-pedidos">
          <h1 className="mt-3"> <span className="heading-border"> Meus Pedidos </span> </h1>

          <Row>
            <UserSidebar/>
            <Col lg={9} xl={10}>
              <div className="meus-pedidos-container">
                <Row className="meus-pedidos-header">
                  <h6> Visualizando todos os pedidos </h6>

                  <Dropdown className="filtro-pedidos">
                    <Dropdown.Toggle variant="success" id="filtro-pedidos-dropdown">
                      Todos
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <span className="d-block py-2 px-3">
                        <FormControl
                          autoFocus
                          className="w-100"
                          placeholder="Filtrar pedido"
                          // onChange={(e) => setValue(e.target.value)}
                          // value={value}
                        />
                      </span>
                      <Dropdown.Item href=""> Pendente </Dropdown.Item>
                      <Dropdown.Item href=""> Em Análise </Dropdown.Item>
                      <Dropdown.Item href=""> Processamento </Dropdown.Item>
                      <Dropdown.Item href=""> Concluido </Dropdown.Item>
                      <Dropdown.Item href=""> Cancelado </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Row>

                <Table responsive="lg" className="table-hover checkout-products-list">
                  <thead className="table-head">
                    <tr>
                      <th className="text-center"> N do pedido </th>
                      <th className="text-center"> Data </th>
                      <th className="text-center"> Pagamento </th>
                      <th className="text-center"> Valor </th>
                      <th className="text-center"> Status </th>
                      <th className="text-center"> </th>
                    </tr>
                  </thead>

                  {
                    this.state.isLoading ? 
                    <tbody className="table-body" title="Ver detalhes do pedido">
                      <tr>
                        <td className="text-center align-center" colSpan={5}>
                          <i className="fas fa-spin fa-spinner fa-2x"></i>
                        </td>
                      </tr>
                    </tbody>
                    :
                    this.state.pedidos.length <= 0 ?
                    <tbody className="table-body" title="Ver detalhes do pedido">
                      <tr>
                        <td className="text-center align-center w-100" colSpan={6}>
                          <div className="alert alert-warning">
                            Nenhum pedido encontrado.
                          </div>
                        </td>
                      </tr>
                    </tbody>
                    :
                    <tbody className="table-body" title="Ver detalhes do pedido">
                      {
                        this.state.pedidos.map((row: any, index: number) => (
                          <tr key={index} onClick={() => {
                            this.setState({modalDetails: !this.state.modalDetails, pedidoCurrent: row}, this.getOrderItems.bind(this, row.PEDIDO))
                          }}>
                            <td className="text-center align-center"> <strong> #{row.PEDIDO} </strong> </td>
                            <td className="text-center align-center"> {row.DATA} </td>
                            <td className="text-center align-center"> {Diversos.getStatusFormapg(row.FORMAPG)} </td>
                            <td className="text-center align-center"> {`R$ ${Diversos.number_format((this.getTotalPedido(row) + row.FRETE),2,",","")}`} </td>
                            <td className={`text-center align-center pedidos-status ${(this.orderStatus(this.state.statusPedido))}`}> {Diversos.getStatusTitulo(row.STATUS)} </td>
                            <td className="text-center align-center">
                              <button type="button" title="Visualizar pedido" className="btn btn-link ver-pdf" onClick={this.handleChildClick.bind(this, row)}>
                                {/* <FontAwesomeIcon icon={faFilePdf}/>  */}
                                <i className="fas fa-search"/>
                              </button>
                            </td>
                          </tr>
                        ))
                      }
                    </tbody>
                  }
                </Table>  

              </div>
            </Col>
          </Row>
        </section>
        
        <Footer/>

        <Modal
        show={this.state.modalDetails}
        onHide={() => this.setState({modalDetails: !this.state.modalDetails, pedidoCurrent: null, pedidoCurrentItems: null})}
        dialogClassName="detalhes-pedido-dialog"
        className="px-0 detalhes-pedido"
        size={'sm'}
        // centered={true}
        >
          {
            !this.state.pedidoCurrent ? 
            <>
              <Modal.Header closeButton className="detalhes-pedido-header">
                <span style={{display: "flex", flexDirection: "column", alignItems: "flex-start"}}>
                  <Modal.Title> # </Modal.Title>
                  <p> Detalhes da compra </p>
                </span>
              </Modal.Header>
              <Modal.Body className="detalhes-pedido-body">
                <div className="alert alert-danger">
                  Nenhum pedido selecionado.
                </div>
              </Modal.Body>
            </>
            :
            <>
              <Modal.Header closeButton className="detalhes-pedido-header">
                <span style={{display: "flex", flexDirection: "column", alignItems: "flex-start"}}>
                  <Modal.Title> #{this.state.pedidoCurrent.PEDIDO} </Modal.Title>
                  <p> Detalhes da compra </p>
                </span>
              </Modal.Header>

              <Modal.Body className="detalhes-pedido-body">
                <h6> Dados do pedido </h6>
                <span className="d-flex align-items-center">
                  <p> <strong> Data: </strong> </p>
                  <p> {this.state.pedidoCurrent.DATA} {this.state.pedidoCurrent.HORA} </p>
                </span>
                <span className="d-flex align-items-center">
                  <p> <strong> Forma de pagamento: </strong> </p>
                  <p> {Diversos.getStatusFormapg(this.state.pedidoCurrent.FORMAPG)} </p>
                </span>
                <span className="d-flex align-items-center">
                  <p> <strong> Status do pedido: </strong> </p>
                  <p> {Diversos.getStatusTitulo(this.state.pedidoCurrent.STATUS)} </p>
                </span>

                <hr className="w-100"/>

                <h6> Dados da entrega </h6>
                <span className="d-flex align-items-center">
                  <p> <strong> Modo de envio: </strong> </p>
                  <p> {this.state.pedidoCurrent.ENTREGA} </p>
                </span>
                {
                  (this.state.pedidoCurrent.ENTREGA === "RETIRA EM LOJA") ?
                  <>
                    <span className="d-flex align-items-center">
                      <p> <strong> Loja: </strong> </p>
                      <p> Loja Santa Cândida </p>
                    </span>
                    <span className="d-flex align-items-center">
                      <p> <strong> Endereço: </strong> </p>
                      <p> Rua Theodoro Makiolka, 885 </p>
                    </span>
                    <span className="d-flex align-items-center">
                      <p> <strong> Bairro: </strong> </p>
                      <p> Santa Cândida </p>
                    </span>
                    <span className="d-flex align-items-center">
                      <p> <strong> Cidade: </strong> </p>
                      <p> Curitiba - PR </p>
                    </span>
                    <span className="d-flex align-items-center">
                      <p> <strong> Telefone: </strong> </p>
                      <p> (41) 3356-3233 </p>
                    </span>
                  </>
                  :
                  <>
                    <span className="d-flex align-items-center">
                      <p> <strong> Endereço: </strong> </p>
                      <p> {this.state.pedidoCurrent.clienteDados.rua}, {this.state.pedidoCurrent.clienteDados.numero} </p>
                    </span>
                    <span className="d-flex align-items-center">
                      <p> <strong> Bairro: </strong> </p>
                      <p> {this.state.pedidoCurrent.clienteDados.bairro} </p>
                    </span>
                    <span className="d-flex align-items-center">
                      <p> <strong> CEP: </strong> </p>
                      <p> {Diversos.maskCEP(this.state.pedidoCurrent.clienteDados.cep)} </p>
                    </span>
                    <span className="d-flex align-items-center">
                      <p> <strong> Cidade: </strong> </p>
                      <p> {this.state.pedidoCurrent.clienteDados.cidade} </p>
                    </span>
                    <span className="d-flex align-items-center">
                      <p> <strong> Prazo de envio: </strong> </p>
                      <p> {this.state.pedidoCurrent.DTENTREGA} </p>
                    </span>
                    {
                      this.state.pedidoCurrent.CODCORREIO &&
                      <span className="d-flex align-items-center">
                        <p> <strong> Código de Rastreamento: </strong> </p>
                        <p> {this.state.pedidoCurrent.CODCORREIO} </p>
                      </span>
                    }
                  </>
                }

                <hr className="w-100"/>

                <h6> Produtos </h6>
                {
                  this.state.isLoadingItems ?
                  <i className="fas fa-spin fa-spinner"></i>
                  :
                  ((!this.state.pedidoCurrentItems) || (this.state.pedidoCurrentItems.length <= 0)) ?
                  <div className="alert alert-warning">Nenhum produto localizado para este pedido</div>
                  :
                  this.state.pedidoCurrentItems.map((row, index) => (
                    <DetailsItem item={row} key={index}/>
                  ))
                }
              </Modal.Body>

              <Modal.Footer className="detalhes-pedido-footer">
                <div className="detalhes-total">
                  <span className="d-flex align-items-center">
                    <p> <strong> Subtotal: </strong> </p>
                    <p> {`R$ ${Diversos.number_format(this.getTotalPedido(this.state.pedidoCurrent),2,",","")}`} </p>
                  </span>
                  <span className="d-flex align-items-center">
                    <p> <strong> Frete: </strong> </p>
                    <p> {`R$ ${Diversos.number_format(this.state.pedidoCurrent.FRETE,2,",","")}`} </p>
                  </span>
                  <span className="d-flex align-items-center">
                    <p> <strong> Total da compra: </strong> </p>
                    <p> {`R$ ${Diversos.number_format( (this.getTotalPedido(this.state.pedidoCurrent) + this.state.pedidoCurrent.FRETE) ,2,",","")}`} </p>
                  </span>
                </div>

                <Button variant="secondary" onClick={() => this.setState({modalDetails: !this.state.modalDetails, pedidoCurrent: null, pedidoCurrentItems: null})}> Fechar </Button>

              </Modal.Footer>
            </>
          }
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.data,
  param: state.param.data,
  cart: state.cart.data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({...UserActions, ...ParamActions, ...CartActions}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MeusPedidos);
