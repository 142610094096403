import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser'; 
import Api from '../../services/api';
import './index.scss';

import Header from '../../components/Header'
import Footer from '../../components/Footer'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Missao from '../../assets/Images/missao.png';
import Atendimento from '../../assets/Images/atendimento.png';
import Demanda from '../../assets/Images/demanda.png';
import Cadastro from '../../assets/Images/cadastro.png';
import Entrega from '../../assets/Images/entrega.png';
import logo from '../../assets/Images/logo.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';


class DicasHC extends Component <{}> {

  api: any = null;
  state: any = {};

  constructor (props: any) {
    super(props);
    this.api = new Api();
    this.state = {
      isLoading: true,
      text: null
    };
  }

  async componentDidMount () {
    const self = this;

    try {
      const {data} = await self.api.get(`/sobre-seguranca`);

      if (!data.status) {
        throw new Error('Nenhum texto cadastrado para esta pagina');
        return false;
      }

      self.setState({
        texto: data.msg.texto
      });
    } catch (e) {
      console.log(e.message);
      self.setState({
        texto: null
      });
    } finally {
      self.setState({
        isLoading: false
      });
    }
  }

  render () {
    return (
      <>

        <Header/>

        <section className="institucional">
          <h1 className="mt-3"> <span className="heading-border"> Dicas Hidroelétrica Colombo </span> </h1>
          {
            this.state.isLoading ? 
            <p className="text-center py-3">
              <i className="fas fa-spin fa-spinner"></i>
            </p>
            :
            <p style={{marginTop: -150}}>{ReactHtmlParser(this.state.texto)}</p>
          }
        </section>

        <Footer/>

      </>
    );
  }
}

export default DicasHC;
