import React, { Component } from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import {ApplicationState} from '../../store';
import {User, UserTypes} from '../../store/ducks/user/types';
import * as UserActions from '../../store/ducks/user/actions';
import './index.scss';
import { Redirect } from "react-router-dom";
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Api from '../../services/api'

interface StateProps { 
  user: User;
} 

interface DispatchProps {
  doLogin(user: User): void;
}

interface OwnProps {}

type Props = StateProps & DispatchProps & OwnProps;

class ResetSenha extends React.Component <Props> {

  api: any = null;
  state: any = null;

  constructor(props) {
    super(props);
    this.api = new Api();
    this.state = {
      isLoading: false,
      email: "",
      hasError: false,
      hasErrorTitle: "",
      hasErrorMsg: "",
    }
  }
  private async handleSubmit () {
    const self = this;

    if (!self.state.email) {
      self.setState({
        hasError: true,
        hasErrorTitle: "Atenção",
        hasErrorMsg: "Email não informado para recuperação de senha",
      });
      return false;
    }

    self.setState({isLoading: true, hasError: false, hasSuccess: false});

    let param = {email: self.state.email};

    try {
      const {data} = await self.api.post('/customer/reset-senha', param);

      if ( !data.status ) {
        throw new Error(data.msg);
      } else {
        self.setState({
        hasSuccess: true,
        hasSuccessTitle: "Sucesso",
        hasSuccessMsg: `Enviamos uma senha provisória para seu e-mail.`,
      });
      }

    } catch (e) {
      console.error(e);
      self.setState({
        hasError: true,
        hasErrorTitle: "Atenção",
        hasErrorMsg: `Não foi possível recuperar senha. ${e.message}`,
      });
    } finally {
      self.setState({isLoading: false});
    }
  }
  
  render () {
    if ((!this.props.user) || (this.props.user.status === false)) {
      return (
        <>
          <Header/>
          <section className="login-container" id="unlogged-user-login">
            <div className="form-container">
              <h1 className="mt-5 lower-font-size"> <span className="heading-border"> Recuperação de Senha </span> </h1>

                {
                  this.state.hasSuccess &&
                  <div className="alert alert-success">
                    <strong>{this.state.hasSuccessTitle}</strong> <br/>
                    {this.state.hasSuccessMsg}
                  </div>
                }

                {
                  this.state.hasError &&
                  <div className="alert alert-danger">
                    <strong>{this.state.hasErrorTitle}</strong> <br/>
                    {this.state.hasErrorMsg}
                  </div>
                }

                <p className="pswd-recovery-text">
                  Insira seu endereço de e-mail e enviaremos a você uma senha temporária.
                </p>
              <div className="input-container" id="unlogged-user-input">

                <Form className="d-flex w-100 mt-5">
                  <Col>
                    <Form.Row>
                      <Form.Group as={Col} controlId="formGridEmail" className="mb-3">
                        <Form.Label className="ml-3"> Seu Email </Form.Label>
                        <Form.Control type="email" placeholder="Email" className="user-input input-border" onChange={(event) => this.setState({email: event.target.value})}/>
                      </Form.Group>
                    </Form.Row>
                  </Col>
                </Form>
              </div>
              <span className="mb-5 text-center">
                <input type="button" value={this.state.isLoading ? 'Recuperando senha...' : 'Recuperar'} className="d-block mt-3 mb-4 btn btn-primary" onClick={this.handleSubmit.bind(this)} disabled={this.state.isLoading}/>
                <a href="/login" className="forgot-pswd"> Voltar </a>
              </span>
              <h3> <span className="font-size-16"> Não tem uma conta? </span> <a href="/cadastro"> Cadastre-se </a> </h3> 
            </div>
          </section>
          <Footer/>
        </>
      );
    } else {
      return <Redirect to="/login" />
    }
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.data,
  param: state.param.data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(UserActions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ResetSenha);
