import React from 'react';
import { Redirect, Link } from "react-router-dom";
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import {ApplicationState} from '../../store';
import {User, UserTypes} from '../../store/ducks/user/types';
import * as UserActions from '../../store/ducks/user/actions';
import {Param, ParamTypes} from '../../store/ducks/param/types';
import * as ParamActions from '../../store/ducks/param/actions';
import {Cart, CartTypes} from '../../store/ducks/cart/types';
import * as CartActions from '../../store/ducks/cart/actions';
import {OrderType, OrderTypes, ItemType} from '../../store/ducks/order/types';
import * as OrderActions from '../../store/ducks/order/actions';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Api from '../../services/api';
import {Diversos} from '../../services/diversos';
import { Table, Button, Row, Col, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import Lottie from 'react-lottie';
import './index.scss';


import successGif from '../../assets/animations/success.json'

interface StateProps { 
  user: User;
  param: Param;
  cart: Cart;
  order: OrderType;
} 

interface DispatchProps {
  doLogin(user: User): void;
  setParam(param: Param): void;
  drop(rowid: string): void;
  dropOrder(): void;
}

interface OwnProps {}

type Props = StateProps & DispatchProps & OwnProps;

class CheckoutFim extends React.Component<Props> {

  api: any = null;
  state: any = null;

  constructor(props) {
    super(props);
    this.api = new Api();
    this.state = {
      redirect: (!this.props.order) ? "/checkout" : null,
      isLoading: false,
      hasError: false,
      hasErrorTitle: "",
      hasErrorMsg: "",
      pedido: this.props.order,
      boletoDc: '',
      hasErrorBoleto: false,
      hasErrorBoletoMsg: ''
      // pedido: {
      //   cliente: {
      //     codigo: 1,
      //     nome: "Cliente Teste 1",
      //     apelido: "Cli",
      //     cpf: "000.254.000-29",
      //     sexo: "1",
      //     nascimento: "27/08/1993",
      //     telefone: "(41) 3246-4533",
      //     celular: "(41) 99999-9999",
      //     email: "c65497180118000714803@sandbox.pagseguro.com.br",
      //     cep: "81030-230",
      //     rua: "Rua Amaro de Santa Rita",
      //     numero: "357",
      //     complemento: "Sala 6 e Sala 7",
      //     bairro: "Fanny",
      //     cidade: "Curitiba",
      //     estado: "PR",
      //     referencia: "Em frente ao parque Unipark",
      //     cepent: "81030-230",
      //     ruaent: "Rua Amaro de Santa Rita",
      //     numeroent: "357",
      //     complementoent: "Sala 6 e Sala 7",
      //     bairroent: "Fanny",
      //     cidadeent: "Curitiba",
      //     estadoent: "PR",
      //     referenciaent: "Em frente ao parque Unipark",
      //     responsavelent: null,
      //     cadastro: "2020-06-02 13:59:47",
      //     ativo: "S",
      //     pessoa: "F",
      //     tipo: "C",
      //     atualizado: "2020-06-02 13:59:47"
      //     },
      //     loja: 1,
      //     data: "01/07/2020",
      //     status: 1,
      //     desconto: 0,
      //     repres: 1,
      //     oper: 1,
      //     entrega: "SEDEX",
      //     dtentrega: "03/07/2020",
      //     frete: 14.9,
      //     tipoped: 1,
      //     formapg: 1,
      //     hora: "09:58",
      //     PEDIDO: 94,
      //     AUTORIZACAO: "0",
      //     NSU: "0",
      //     TID: "0",
      //     GATEWAY: "CIELO",
      //     PS_CODE: "65BDAAD4-737B-4783-8AC4-71F4428C4742",
      //     PS_DATE: "2020-07-01T09:58:57.000-03:00",
      //     PS_RATE_AMOUNT: "15.90",
      //     PS_FEE_AMOUNT: "1.03",
      //     PS_GATEWAY: "PagSeguro",
      //     BOLETO_URL: null,
      //     itens: [
      //     {
      //     produto: 3384,
      //     qtd: 1,
      //     valor: 1,
      //     nome: "LIMA MTWO 21MM WP16 701 SORT 1004 A 2506: CART C4 UNID"
      //     }
      //     ],
      //     cartao: [
      //       {   
      //         numero: "1",
      //         validade: "1",
      //         cvv: "1"
      //       }
      //     ]
      //     },
      //     pagseguro: {},
    }
  }

  componentDidMount () {
    const self = this;
    self.props.dropOrder();

    // SE FORMA DE PAGAMENTO FOR BOLETO TRATA SHOPLINE
    if ( self.state.pedido.formapg == 2 ) {
      self._handleShopline();
    }
  }

  /**
   * BUSCA STRING CRIPTOGRAFADA DO SHOPLINE COM DADOS DO PEDIDO PARA REPASSAR AO ITAU
   */
  private async _handleShopline () {
    const self = this;
    self.setState({isLoading: true});

    let param = { pedido: self.state.pedido.pedido };

    try {
      const {data} = await self.api.post(`/order/shopline/session`, param);

      if ( !data.status ) 
        throw new Error( data.msg );
      else
        self.setState({boletoDc: data.msg});

    } catch (e) {
      console.log(e);
      self.setState({hasErrorBoleto: true, hasErrorBoletoMsg: e.message});
    } finally {
      self.setState({isLoading: false});
    }
  }

  private getSubTotal () {
    const self = this;
    let total = 0.00;
    for ( var i = 0; i < self.state.pedido.itens.length; i++ ) {
      total += self.state.pedido.itens[i].qtd * self.state.pedido.itens[i].valor;
    }
    return total;
  }

  render () {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }

    const mdDevice = window.innerWidth <= 768;

    return (
      <>
        <Header/>

        <section className="content-fluid checkout-fim-container mx-auto mb-5">
          <h1 className="my-5 pt-5">Pedido efetuado!</h1>
        <Row>
          <Col md={6}>
            <Row>
              <Col className="px-0">
                <Card className="cart-card-wrapper">
                  <Card.Header> <FontAwesomeIcon icon="check-circle" className="mr-2" /> Pedido Confirmado </Card.Header>
                  <Card.Body className="text-center">
                    <div className="card-1-title color-primaryHover mb-2">Seu pedido foi realizado com sucesso!</div>
                    <br/>
                    <Lottie 
                      options={{
                        loop: true,
                        autoplay: true, 
                        animationData: successGif,
                      }}
                      height={150}
                      width={150}
                      isStopped={false}
                      isPaused={false}
                    />
                    <br/>
                    <div className="card-1-text text-dark">O número do seu pedido é:</div>
                    <br/>
                    <div className="card-1-pedido color-secondary"> {this.state.pedido.pedido} </div>
                    <br/>
                    <div className="card-1-msg text-dark mt-1">
                      Você receberá uma notificação no e-mail <span className="color-secondary"><b>xxxxxx@xxx.com.br</b></span> com todos os detalhes do pedido.
                      <br/>
                      Aguardamos a confirmação do pagamento.
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col md={6}>
            {
              this.state.pedido.formapg == 2 && 
              <Row className={ !mdDevice ? 'mx-0' : 'mt-5'}>
                <Col className="px-0">
                  <Card className="cart-card-wrapper">
                    <Card.Header> <FontAwesomeIcon icon="barcode" className="mr-2" /> Boleto bancário </Card.Header>
                    <Card.Body className="text-left">
                      
                      <Row>
                        <Col>
                          <h4>Seu boleto foi gerado com sucesso!</h4>
                          <br/>
                          <table className="table table-sm">
                            <tr>
                              <td colSpan={2} className="text-center py-3">
                                {/* <form method="post" target="_blank" action="https://shopline.itau.com.br/shopline/shopline.aspx"> */}
                                <form method="post" target="_blank" action="https://shopline.itau.com.br/shopline/impressao.aspx">
                                  <input type="hidden" name="DC" id="DC" value={this.state.boletoDc}/>
                                  <button className="btn btn-lg btn-primary" disabled={this.state.isLoading}>
                                    {
                                      this.state.isLoading ?
                                      <>
                                        <FontAwesomeIcon icon="spinner" spin className="mr-2"/>
                                        Gerando boleto, por favor aguarde...
                                      </>
                                      :
                                      <>
                                        <FontAwesomeIcon icon="print" className="mr-2"/>
                                        Clique aqui para imprimir seu boleto
                                      </>
                                    }
                                  </button>
                                </form>
                                {/* <a href={this.state.pedido.boleto_url} target="_blank" className="btn btn-lg btn-primary">
                                  <FontAwesomeIcon icon="print" className="mr-2"/>
                                  Clique aqui para imprimir seu boleto
                                </a> */}
                              </td>
                            </tr>
                          </table>
                        </Col>
                      </Row>

                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            }
          </Col>
        </Row>
          
          <Row className="mt-5">
            <Col>
              <Card className="cart-card-wrapper"> 
                <Card.Header> <FontAwesomeIcon icon="clipboard-list" className="mr-2" /> Detalhes do pedido</Card.Header>
                <Card.Body className="text-left">
                  
                  <Row className="mb-4">
                    <Col>
                      <span className="card-2-title color-primaryHover">Compra realizada em {this.state.pedido.data} às {this.state.pedido.hora}</span>
                      <br/>
                      <span className="card-2-msg">Comprado por {this.state.pedido.cliente.nome}</span>
                    </Col>
                  </Row>

                  <Row className="my-5">
                    <Col>
                      <h4>Produtos:</h4>
                      <Table responsive="md">
                        <thead className="table-head">
                          <tr>
                            <th className="table-heading">Produto</th>
                            <th>Quantidade</th>
                            <th className="th-min-width-1">Preço unitário</th>
                            <th className="th-min-width-2">Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            this.state.pedido.itens.map((row, index) => (
                              <tr key={index}>
                                <td>({row.produto}) {row.nome}</td>
                                <td>{row.qtd}</td>
                                <td>R$ {Diversos.number_format(row.valor,2,",",".")}</td>
                                <td>R$ {Diversos.number_format((row.valor * row.qtd),2,",",".")}</td>
                              </tr>
                            ))
                          }
                        </tbody>
                      </Table>
                    </Col>
                  </Row>

                  <Row className="m-auto checkout-detalhes-container">
                    <Col xs={12} md={6} className="rmv-padding-mobile">
                      <span className="checkout-detalhes-wrapper">
                        <h4>Forma de pagamento: </h4>
                        <table className="table table-sm">
                          <tbody>
                            <tr>
                              <td>
                                {
                                  (this.state.pedido.formapg == 1) ?
                                  <span>
                                    Cartão de crédito <br/>
                                    {this.state.pedido.cartao.bandeira} (final ...{this.state.pedido.cartao.numero})
                                  </span>
                                  :
                                  <span>
                                    Boleto bancário <br/>
                                    <a href={this.state.pedido.boleto_url} target="_blank" className="btn btn-sm btn-primary">
                                      <FontAwesomeIcon icon="print" className="mr-2"/>
                                      Imprimir boleto
                                    </a>
                                  </span>
                                }
                                
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </span>
                      <span className="checkout-detalhes-wrapper">
                        <h4>Forma de entrega: </h4>
                        <table className="table table-sm">
                          <tbody>
                            <tr>
                              <th>Forma:</th>
                              <td>{this.state.pedido.entrega}</td>
                            </tr>
                            <tr>
                              <th>Prazo:</th>
                              <td>{this.state.pedido.dtentrega}</td>
                            </tr>
                            <tr>
                              <th>Preço:</th>
                              <td>R$ {Diversos.number_format(this.state.pedido.frete,2,",","")}</td>
                            </tr>
                          </tbody>
                        </table>
                      </span>
                    </Col>

                    <Col xs={12} md={6} className="rmv-padding-mobile">
                    <span className="checkout-detalhes-wrapper">
                      <h4>Endereço de entrega: </h4>
                        <table className="table table-sm">
                          <tbody>
                            <tr>
                              <td>
                                {this.state.pedido.cliente.rua}, {this.state.pedido.cliente.numero}, <br/>
                                {this.state.pedido.cliente.complemento} <br/>
                                {this.state.pedido.cliente.bairro} <br/>
                                {this.state.pedido.cliente.cidade} - {this.state.pedido.cliente.estado} <br/>
                                {this.state.pedido.cliente.cep}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </span>
                      <span className="checkout-detalhes-wrapper">
                        <h4> Resumo: </h4>
                        <table className="table table-sm">
                          <tbody>
                            <tr>
                              <th> Subtotal </th>
                              <td> R$ {Diversos.number_format(this.getSubTotal(),2,",","")} </td>
                            </tr>
                            <tr>
                              <th>Frete</th>
                              <td>R$ {Diversos.number_format(this.state.pedido.frete,2,",","")}</td>
                            </tr>
                            <tr>
                              <th>TOTAL</th>
                              <td>R$ {Diversos.number_format((this.getSubTotal() + this.state.pedido.frete),2,",","")}</td>
                            </tr>
                          </tbody>
                        </table>
                      </span>
                    </Col>
                  </Row>

                  {
                    (this.state.pedido.entrega === "RETIRA EM LOJA") &&
                    <Row className="mt-0">
                      <Col>
                        <div className="alert alert-warning">
                          <strong>ATENÇÃO - Modo de entrega RETIRA EM LOJA</strong> <br/>
                          <ul>
                            <li>Endereço para retirada: Rua Theodoro Makiolka, 885 - Santa Cândida </li>
                            <li>Horário: de segunda a sexta das 8h30 as 18h30</li>
                            <li>Aguarde o recebimento do e-mail com a autorização para retirada de seu pedido. </li>
                            <li>Em caso de dúvidas entre em contato 41 3356-3233 </li>
                            <li>*Obrigatória apresentação de documento com foto do titular da compra. </li>
                          </ul>
                        </div>
                      </Col>
                    </Row>
                  }

                </Card.Body>

                <Card.Footer className="text-center">
                  <a href="/" target="_self" className="btn checkout-finalizar-compra btn-lg"> <FontAwesomeIcon icon="arrow-left"/> Voltar para loja</a>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
          
        </section>
        
        <Footer/>
      </>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.data,
  param: state.param.data,
  cart: state.cart.data,
  order: state.order.data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({...UserActions, ...ParamActions, ...CartActions, ...OrderActions}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CheckoutFim);
